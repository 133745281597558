import Cookies from 'js-cookie'

export const getAccessToken = () => {
  return Cookies.get('authToken')
}

export const saveAccessToken = (value) => {
  Cookies.set('authToken', value)
}

export const removeAccessToken = () => {
  Cookies.remove('authToken')
}
