import { BEConfig } from "../config/env";
import {
  fetchWebApiWithParam,
  postAuthWebApi,
  fetchDownloadApi,
} from "../webapis/apiResource";
import { getAccessToken } from "../util/auth";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  successSnackBar,
  errorSnackBar,
} from "../actions/common.action";
import { handleFetchError } from "../util/errorHandler";
import {
  GET_DASHBOARD_DATA,
  CLEAR_DASHBOARD_DATA,
  GET_HOTEL_SEARCH_DATA,
  GET_AI_PRICE_GENERTOR,
} from "../constants/action.constant";
import store from "../store";
export const getDashboardData = (hotels) => ({
  type: GET_DASHBOARD_DATA,
  hotels,
});
export const clearDashboardData = () => ({
  type: CLEAR_DASHBOARD_DATA,
});
export const getHotelSearchData = (results) => ({
  type: GET_HOTEL_SEARCH_DATA,
  results,
});

export const getAiPriceGenerator = (results) => ({
  type: GET_AI_PRICE_GENERTOR,
  results,
});

export const fetchDashboardData = (
  date,
  showLoader = true,
  page,
  searchText = "",
  clear = false,
  orderBy = "asc"
) => {
  const { baseURL, protocol, port, versionInfo, dashboardPageHandle } =
    BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${dashboardPageHandle}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    if (clear) dispatch(clearDashboardData());
    return fetchWebApiWithParam(getAccessToken(getState), apiURL, {
      startDate: date,
      pageNumber: page || 1,
      searchText,
      orderBy,
    })
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        if (response.data) {
          dispatch(getDashboardData(response.data));
        }
        return response;
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const downloadReport = (
  startDate,
  pageNumber,
  searchText,
  showLoader = true
) => {
  const { baseURL, protocol, port, versionInfo, getHotelReportHandler } =
    BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${getHotelReportHandler}?startDay=${startDate}&pageNumber=${pageNumber}&searchText=${searchText}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return fetchDownloadApi(getAccessToken(getState), apiURL)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `PriceReport_${startDate}.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateAIPrice = (data, hotelId, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, updateAIPrice } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${updateAIPrice}${hotelId}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(successSnackBar("AI Price updated succesfully"));
        return response;
      })
      .catch((error) => {
        store.dispatch(
          errorSnackBar("Something went wrong, please try again.")
        );
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        throw error.response;
      });
  };
};

export const fetchHotelSearchResults = (query, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, dashboardHotelSearchHandle } =
    BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${dashboardHotelSearchHandle}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return fetchWebApiWithParam(getAccessToken(getState), apiURL, {
      searchText: query,
    })
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        if (response.data && response.data.length) {
          dispatch(getHotelSearchData(response.data));
        }
        return response;
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        dispatch(getHotelSearchData([]));
        return error.response;
      });
  };
};

export const fetchAiPriceGenerator = (hotelId, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, aiPriceGenerator } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${aiPriceGenerator}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return fetchWebApiWithParam(getAccessToken(getState), apiURL, {
      hotelId: hotelId,
    })
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(successSnackBar('AI is generating recommendation please refresh after 20 sec.', {autoHideDuration: 6000 }));
        return response;
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        dispatch(getAiPriceGenerator([]));
        return error.response;
      });
  };
};

export const singleDateScrape = (hotelId, scrapeDate, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, singleDateScrape } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${singleDateScrape}/${hotelId}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return fetchWebApiWithParam(getAccessToken(getState), apiURL, {
      scrapeDate: scrapeDate,
    })
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(successSnackBar('Hotel Price Fetching Request Submitted, Please Refresh After 1 Min.', {autoHideDuration: 6000 }));
        return response;
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const pushAIPrice = (hotelId, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, pushAiPrice } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${pushAiPrice}/${hotelId}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return postAuthWebApi(getAccessToken(getState), apiURL, {})
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(successSnackBar('AI Prices have been pushed to AIS system', {autoHideDuration: 6000 }));
        return response;
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};