import React, {Component} from 'react';
import AIPriceTitle from './AIPriceTitle';
import AIPriceData from './AIPriceData';

class AIPrice extends Component {
    constructor(props) {
        super(props);
        this.state={
            showInput: false,
            inputValue: ''
        }
        this.regex=/^[0-9]{0,3}$/;
    }

    showInputField=(e,mainIndex,index)=>{
        let selectedCell = document.getElementById(`AIprice_${mainIndex}_${index}`);
        let parentNode = selectedCell.parentNode;
        if(parentNode.classList.contains('edit-Mode')){
            parentNode.querySelector(".show").classList.remove('show');
        }else {
            parentNode.classList.add('edit-Mode');
        }
        let priceField = selectedCell.childNodes[0];
        let inputField = selectedCell.childNodes[1];
        inputField.classList.add('show');
        inputField.focus();
        this.setState({
            inputValue : priceField.innerText,
        })
    }
    updateValue = (e,index) =>{
        const {item,setAiPriceList} = this.props
        let inputValue = e.target.value;
        let priceField = e.target.parentNode.childNodes[0];
        if(this.regex.test(inputValue)){
            if(inputValue===priceField.innerText || inputValue.length===0){
                priceField.innerText = item.aiPrice;
            }else {
                priceField.innerText = inputValue;
            }
            this.setState({
                inputValue,
            })
            setAiPriceList(index,priceField.innerText)
        }

    }
    inputOnClick=(index)=>{
        const { item, openPriceUpdateDialog } = this.props;
        openPriceUpdateDialog(index, item)
    }
    render(){
        const {mainIndex,index,item} = this.props;
        return (
            <li key={`${mainIndex}_${index}`} id={`AIprice_${mainIndex}_${index}`}>
                <span onClick={() => this.inputOnClick(index)} style={{cursor: 'pointer'}}>{Math.round(item.aiPrice)}</span>
            </li>
        )
    }
}

export default AIPrice;