import axios, { CancelToken } from 'axios'

function getConfig (accessToken, visitorId = '', language, countryId, storeId, defaultHeaders, isPrevMode) {
  let cancel
  // Return the cancel function and add it to the header.
  // See: https://github.com/mzabriskie/axios#cancellation
  const header = {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  }
  const defaultHeader = {
    'Content-Type': 'application/json'
  }

  const config = {
    cancelToken: new CancelToken(c => {
      // An executor function receives a cancel function as a parameter
      cancel = c
    }),
    headers: defaultHeaders ? defaultHeader : header
  }

  return {
    cancel,
    config
  }
}

export function webapiGet (accessToken, url, visitorId, defaultHeaders) {
  const config = getConfig(accessToken, visitorId, defaultHeaders)
  return {
    request: axios.get(url, config.config),
    cancel: config.cancel
  }
}

export function webapiDownload (accessToken, url, visitorId, defaultHeaders) {
  const config = getConfig(accessToken, visitorId, defaultHeaders)
  console.log(config.config);
  return {
    request: axios.get(url, {
      ...config.config,
      header: {
        ...config.config.headers,
        'Content-Type': 'Content-type: text/csv',
        'Content-disposition': 'attachment'
      }
    }),
    cancel: config.cancel
  }
}

export function webapiGetWithParams (accessToken, url, options) {
  const config = getConfig(accessToken)
  return {
    request: axios.get(url, { ...config.config, params: options }),
    cancel: config.cancel
  }
}
export function webapiPut (accessToken, url, options) {
  const config = getConfig(accessToken)
  return {
    request: axios.put(url, options, config.config),
    cancel: config.cancel
  }
}

export function webapiPost (accessToken, url, options, defaultHeaders) {
  const config = getConfig(accessToken, defaultHeaders)
  return {
    request: axios.post(url, options, config.config),
    cancel: config.cancel
  }
}

export function webapiDelete (accessToken, url) {
  const config = getConfig(accessToken)
  return {
    request: axios.delete(url, config.config),
    cancel: config.cancel
  }
}

export function webapiAuthPost (url, options) {
  return {
    request: axios.post(url, options)
  }
}
