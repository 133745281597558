import React, { useState } from 'react'
import Modal from '../modal/modal'
import './../modal/modal.scss'
import Input from '../inputs/input-simple'
import { Grid } from '@material-ui/core'

export default function DeleteModal (props) {
    const { updateHandler, open, dialogClose, moduleName } = props;
    const [price, setPrice] = useState("");
    const [reason, setReason] = useState("");
    const [reasonOther, setReasonOther] = useState("");
    return (
      <Modal
        open={open}
        onClose={dialogClose}
        fullWidth="true"
        maxWidth="sm"
        modalHeading={`Update ${moduleName}`}
        modalSaveTxt="Yes"
        modalCloseTxt="No"
        onClick={() =>
          updateHandler(price, reason === "Other" ? reasonOther : reason)
        }
        showActionBtn
        savebtnClass="createBtn"
        cxlbtnClass="cancelBtn"
        primary
      >
        <p>Are You Sure You Want to Update {`${moduleName}`}?</p>

        <Grid
          xs={10}
          container
          className="subtaskrow-1"
          style={{ margin: "8px 0px" }}
        >
          <Grid xs={3} className="label" item style={{ margin: "8px 0px" }}>
            Price
          </Grid>
          <Grid xs={7} item>
            <Input
              type="text"
              label="Price"
              value={price}
              valid={true}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Grid>
          <Grid xs={12} item>
            Reason
          </Grid>
          <Grid xs={12} item>
            <input
              type="radio"
              id="Event"
              checked={reason === "Event"}
              onChange={(e) => setReason("Event")}
            />
            <label htmlFor="Event">Event</label>
          </Grid>
          <Grid xs={12} item>
            <input
              type="radio"
              id="Market Demand"
              checked={reason === "Market Demand"}
              onChange={(e) => setReason("Market Demand")}
            />
            <label htmlFor="Market Demand">Market Demand</label>
          </Grid>
          <Grid xs={12} item>
            <input
              type="radio"
              id="Other"
              checked={reason === "Other"}
              onChange={(e) => setReason("Other")}
            />
            <label htmlFor="Other">Other</label>
          </Grid>
          {reason === "Other" && (
            <Grid xs={12} item>
              <Input
                type="text"
                label="Please enter reason"
                value={reasonOther}
                valid={true}
                onChange={(e) => setReasonOther(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    );
}
