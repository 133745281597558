import React from "react";
import TagsInput from "react-tagsinput";
import Autosuggest from "react-autosuggest";

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
    };
  }

  render() {
    let autocompleteRenderInput = ({ addTag, ...props }) => {
      const handleOnChange = (e, { newValue, method }) => {
        if (method === "enter") {
          e.preventDefault();
        } else {
          props.onChange(e);
        }
      };

      const inputValue =
        (props.value && props.value.trim().toLowerCase()) || "";
      const inputLength = inputValue.length;

      let suggestions = this.props.competitorList.filter((state) => {
        return state.name.toLowerCase().slice(0, inputLength) === inputValue;
      });
      return (
        <Autosuggest
          ref={props.ref}
          suggestions={suggestions}
          shouldRenderSuggestions={(value) => value && value.trim().length > 0}
          getSuggestionValue={(suggestion) =>
            this.props.setCompetitorsId(suggestion.id)
          }
          renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
          inputProps={{ ...props, onChange: handleOnChange }}
          onSuggestionSelected={(e, { suggestion }) => {
            addTag(suggestion.name);
          }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={() => {}}
        />
      );
    };
    let defaultRenderTag = (props) => {
      let {
        tag,
        key,
        disabled,
        onRemove,
        classNameRemove,
        getTagDisplayValue,
        ...other
      } = props;
      return (
        <span key={key} {...other}>
          {getTagDisplayValue(tag)}
          {!disabled && (
            <a
              className={classNameRemove}
              onClick={(e) => {
                onRemove(key);
                this.props.deleteCompetitorsId(key);
              }}
            />
          )}
        </span>
      );
    };
    return (
      <TagsInput
        inputProps={{
          placeholder: "Select Competitors",
        }}
        renderInput={autocompleteRenderInput}
        addKeys={[]}
        renderTag={defaultRenderTag}
        onlyUnique={true}
        maxTags={50}
        value={this.props.selectedCompetitorName}
        onChange={(e) => {
          this.props.onInputChange(e, "selectCompetitor");
        }}
      />
    );
  }
}

export default Autocomplete;
