import React, { Component } from 'react'
import { Accordion } from 'semantic-ui-react';
import HotelTopSection from '../HotelTopSection'
import AccordionBottomSection from '../AccordionBottomSection'
export default class AccordionSection extends Component {
  constructor(props) {
    super(props);
  }
  
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >  
        <HotelTopSection />
          
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <AccordionBottomSection />
        </Accordion.Content>
      </Accordion>
    )
  }
}