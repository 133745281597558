import React, { Component } from "react";
import { connect } from "react-redux";

export const Loader = () => (
  <div className="spinnerBg">
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  </div>
);
class LoaderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowLoader: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.showLoader != this.props.showLoader) {
      this.setState({ isShowLoader: this.props.showLoader });
    }
  };

  render = () => (this.state.isShowLoader ? <Loader /> : "");
}

const mapStateToProps = (state) => ({
  showLoader: state.common.showLoader,
});

export default connect(mapStateToProps, null)(LoaderContainer);
