import React, { Component } from "react";
import AccordionSection from "./AccordionSection";
import { Accordion, Icon } from "semantic-ui-react";
import HotelTopSection from "./HotelTopSection";
import AccordionBottomSection from "./AccordionBottomSection";

class Hotel extends Component {
  constructor(props) {
    super(props);
  }
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { hotelList = [], noOfDays, updateAIPrice, searchText, pageNumber } = this.props;
    return (
      <div className="hotel-container">
        <ul>
          {hotelList.map((hotelData, index) => {
            return (
              <li key={hotelData.id}>
                <Accordion>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={this.handleClick}
                  >
                    <HotelTopSection
                      hotelData={hotelData}
                      noOfDays={noOfDays}
                    />
                  </Accordion.Title>

                  <Accordion.Content active={activeIndex === index}>
                    {activeIndex === index && (
                      <AccordionBottomSection
                        searchText={searchText}
                        pageNumber={pageNumber}
                        hotelData={hotelData}
                        noOfDays={noOfDays}
                        mainIndex={index}
                        activeIndex={activeIndex}
                        updateAIPrice={updateAIPrice}
                      />
                    )}
                  </Accordion.Content>
                </Accordion>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Hotel;
