import React, { useEffect, useMemo, useState } from "react";
import "./index.scss";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "@material-ui/lab/Pagination";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeleteIcon from "@material-ui/icons/Delete";

import CreateEventDialog from "./create-event-dialog";

import { deleteEvent, fetchHotelEvents } from "../../actions/events.action";

import moment from "moment";
import { eventCardStying } from "../../util/commonStyling";
import { compareEventDates } from "../../util/commonFunctions";
import HotelSearchInput from "../Common/HotelSearchInput";
import { ConfirmationDialog } from "../ReviewForm/confirm-dialog";
import { singleDateScrape } from "../../actions/dashboard.action";

const COLOUMN_WIDTH = "550px";
const DEFAULT_PAGE_NUMBER = 1;

const EventsList = (props) => {
  const [eventStartDate, setEventStartDate] = React.useState(new Date());
  const [openCreateEventDialog, setOpenCreateEventDialog] =
    React.useState(false);
  const [hotelDetails, setHotelDetails] = React.useState(undefined);
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedEventId, setSelectedEventId] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [hotelEvents, setHotelEvents] = useState([]);

  const { startMonthLabel, endMonthLabel, startMonthDate, endMonthDate } =
    useMemo(() => {
      return {
        startMonthLabel: moment(eventStartDate).format("MMMM YYYY"),
        endMonthLabel: moment(eventStartDate)
          .add(1, "months")
          .format("MMMM YYYY"),
        startMonthDate: moment(eventStartDate).startOf("month").format("l"),
        endMonthDate: moment(eventStartDate)
          .add(1, "months")
          .endOf("month")
          .format("l"),
      };
    }, [eventStartDate]);

  useEffect(() => {
    getHotelEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStartDate, hotelDetails, page]);

  const getHotelEvents = async () => {
    let { events, total } = await props.fetchHotelEvents({
      startDate: startMonthDate,
      endDate: endMonthDate,
      hotelId: hotelDetails?.id,
      page,
    });

    if (hotelDetails?.id) {
      events = events.map((event) => ({
        ...event,
        hotelName: hotelDetails.name,
        hotelID: hotelDetails.id,
      }));
    }

    const groupedEvents = events.reduce((acc, event) => {
      const { hotelID } = event;
      acc[hotelID] = acc[hotelID] || [];
      acc[hotelID].push(event);
      return acc;
    }, {});

    const hotelEvents = Object.values(groupedEvents)
      .filter((hotel) => hotel?.[0]?.hotelName)
      .map((value) => {
        const hotel = {
          hotelName: value[0].hotelName,
          hotelID: value[0].hotelID,
          [startMonthLabel]: [],
          [endMonthLabel]: [],
        };

        const allEvents = [...(groupedEvents[null] || []), ...(value || [])];
        if (Array.isArray(allEvents)) {
          allEvents.sort(compareEventDates).forEach((v) => {
            const label = moment(v.eventDate).format("MMMM YYYY");

            if (Array.isArray(hotel?.[label]) && v.eventName) {
              hotel[label].push({
                eventID: v.eventID,
                eventName: v.eventName,
                eventDate: v.eventDate,
                price: v.price,
                formatedEventDate: moment(v.eventDate).format(
                  "ddd, MMM DD YYYY"
                ),
              });
            }
          });
        }

        return hotel;
      });

    setHotelEvents(hotelEvents);
    setTotalCount(total);
  };

  const handleCreateEvent = () => {
    setOpenCreateEventDialog(true);
  };

  const handleCloseEventDialog = (refetchData = false) => {
    if (refetchData) {
      getHotelEvents();
    }
    setOpenCreateEventDialog(false);
  };

  const changeEventStartDate = (type) => {
    if (type === "ADD") {
      setEventStartDate(
        new Date(eventStartDate.setMonth(eventStartDate.getMonth() + 2))
      );
    } else {
      setEventStartDate(
        new Date(eventStartDate.setMonth(eventStartDate.getMonth() - 2))
      );
    }
  };

  const onSearchSelected = (_, hotel) => {
    setHotelDetails(hotel);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedEventId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedEventId(null);
    setOpenDeleteDialog(false);
  };

  const handleConfirmDeleteDialog = async () => {
    await props.deleteEvent(selectedEventId);
    getHotelEvents();
    handleCloseDeleteDialog();
  };

  return (
    <div className="event-form-list-container">
      {openCreateEventDialog && (
        <CreateEventDialog handleClose={handleCloseEventDialog} />
      )}

      {openDeleteDialog && (
        <ConfirmationDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDeleteDialog}
          title="Confirmation"
          content="Are you sure you want to delete this event?"
        />
      )}

      <div className="header-container">
        <p className="title">Event Form</p>

        <div className="d-flex align-items-center gap-2">
          <div style={{ width: "400px", marginRight: "10px" }}>
            <HotelSearchInput onSearchSelected={onSearchSelected} />
          </div>
          <button className="primary-btn" onClick={handleCreateEvent}>
            Create Event
          </button>
        </div>
      </div>

      <TableContainer
        component={Paper}
        className="list-table-container"
        style={{ maxHeight: "75vh" }}
      >
        <Table  sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="hotel-name">
                <strong style={{ fontSize: 16 }}>Hotel Name</strong>
              </TableCell>
              <TableCell align="center">
                <div className="event-date-header">
                  <ArrowBackIosIcon
                    onClick={() => changeEventStartDate("SUBTRACT")}
                  />
                  <p>{startMonthLabel}</p>
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="event-date-header">
                  <p style={{ width: "120px" }}>{endMonthLabel}</p>
                  <ArrowForwardIosIcon
                    onClick={() => changeEventStartDate("ADD")}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hotelEvents?.map((row) => (
              <TableRow
                key={row.hotelID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{ fontSize: 16 }}
                  component="th"
                  scope="row"
                  className="hotel-name"
                >
                  {row.hotelName}
                </TableCell>
                {!row[endMonthLabel]?.length && !row[endMonthLabel]?.length ? (
                  <TableCell colSpan={2} align="center" style={{ fontSize: 16, width: COLOUMN_WIDTH }}>
                    <span style={{color: "rgb(175 175 175 / 87%)"}}>No Events for this Hotel in this period</span>
                  </TableCell>
                ) : (
                  <>
                    <TableCell align="center" style={{ fontSize: 16, width: COLOUMN_WIDTH }}>
                      <EvetnsCardContainer
                        events={row[startMonthLabel] || []}
                        handleOpenDeleteDialog={handleOpenDeleteDialog}
                        onClickRefeshScrapeDate={() =>
                          props?.singleDateScrape(
                            row?.hotelID,
                            moment(hotelEvents?.eventDate).format("YYYY-MM-DD")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: 16, width: COLOUMN_WIDTH }}>
                      <EvetnsCardContainer
                        events={row[endMonthLabel] || []}
                        handleOpenDeleteDialog={handleOpenDeleteDialog}
                        onClickRefeshScrapeDate={() =>
                          props?.singleDateScrape(
                            row?.hotelID,
                            moment(hotelEvents?.eventDate).format("YYYY-MM-DD")
                          )
                        }
                      />
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {Boolean(totalCount) && (
        <div className="row align-items dashboard-pagination mt-3">
          <div className="col-md-12 d-flex justify-content-center">
            <Pagination
              variant="outlined"
              count={totalCount}
              page={page}
              onChange={handleChangePage}
              color="primary"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
};

const EvetnsCardContainer = ({
  onClickRefeshScrapeDate,
  events,
  handleOpenDeleteDialog,
}) => {
  return (
    <div className="events-card-container">
      {events?.map((e, index) => (
        <EventCard
          key={e.eventID}
          event={e}
          index={index}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          onClickRefeshScrapeDate={onClickRefeshScrapeDate}
        />
      ))}
    </div>
  );
};

const EventCard = ({
  onClickRefeshScrapeDate,
  event,
  index,
  handleOpenDeleteDialog,
}) => {
  console.log(event);
  return (
    <div className="d-flex align-items-center">
      <div
        className="event-card"
        style={
          index % 2 === 0
            ? eventCardStying["evenIndexStyle"]
            : eventCardStying["oddIndexStyle"]
        }
      >
        <div className="event-info-container">
          <p className="event-name">
            <strong>{event.eventName}</strong>
          </p>

          <p className="event-date">{event.formatedEventDate}</p>
        </div>
        <div className="event-price-info-container">
          <p>${event?.price || 0}</p>
          <div onClick={() => onClickRefeshScrapeDate?.()}>
            <img src="/images/refresh.svg" alt="refresh" />
          </div>
        </div>
      </div>
      <DeleteIcon
        className="delete-event-icon"
        onClick={() => handleOpenDeleteDialog(event.eventID)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  reviewFormList: state.reviewFormReducer.reviewFormList,
});
const mapDispatchToProps = (dispatch) => ({
  fetchHotelEvents: bindActionCreators(fetchHotelEvents, dispatch),
  deleteEvent: bindActionCreators(deleteEvent, dispatch),
  singleDateScrape: bindActionCreators(singleDateScrape, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EventsList);
