import React, {Component} from "react";
import moment from "moment";
class Calander extends Component{
    constructor(props) {
        super(props);
        this.totalMiliSec = 86400000;
        this.state = {
            dateList : []
        }
    }
    componentDidMount() {
        this.initializeDate();
    }

    initializeDate =  ()=>{
        let dateList = [];
        const {days,startDate} = this.props;
        let currentDate = new Date(startDate).getTime();
        for (let i=0; i<days; i++){
            dateList.push(currentDate+(this.totalMiliSec*i))
        }
        this.setState({
            dateList: dateList,
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.startDate !== this.props.startDate){
            this.initializeDate();
        }
    }

    render() {
        const {changeCurrentDate,startDate} = this.props;
        return (
            <div className="daySelectArea">
                <div className="daySelectLeft"></div>
                <div className="daySelectRight">
                <span className="calendar-left-arrow" onClick={()=>changeCurrentDate(false,startDate)}><img src="/images/left-arrow.svg" alt="left arrow" /></span>
                    <ul>
                        {this.state.dateList.map((item,index)=>{
                            return  <li key={index}><span>{moment(item).format("ddd")}</span><span>{moment(item).format("DD MMM")}</span></li>
                        })}
                    </ul>
                <span className="calendar-right-arrow" onClick={()=>changeCurrentDate(true,startDate)}><img src="/images/right-arrow.svg" alt="right arrow" /></span>
                </div>
            </div>
        )
    }
}

export default Calander;