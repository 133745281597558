function baseUrl(defaultUrl) {
  let url;

  if (process.env.DOMAIN_NAME !== undefined) {
    url = `https://${process.env.DOMAIN_NAME}`;
  } else {
    url = defaultUrl;
  }

  return url;
}

export const config = {
  development: {
    env: process.env.NODE_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: "http://localhost:3000",
    googleMap:
      process.env.GOOGLE_MAP_API_KEY ||
      "AIzaSyAtrprDyHGacssn71XBy5EaD0EgT9bHH9Y",
  },
  staging: {
    env: process.env.NODE_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl("https://bfl-web-client.qa3.tothenew.net"),
    googleMap:
      process.env.GOOGLE_MAP_API_KEY ||
      "AIzaSyAtrprDyHGacssn71XBy5EaD0EgT9bHH9Y",
  },
};

export const BEConfig = {
  protocol: process.env.REACT_APP_PROTOCOL || "https://",
  port: process.env.API_PORT || "8000",
  baseURL:
    process.env.REACT_APP_CART_API_BASE_URL || "ai.thepredictiveminds.com",
  feedbackApplicationBaseURL:
    process.env.REACT_APP_FEEDBACK_APPLICATION_BASE_URL ||
    "http://50.18.237.119:8443/feedback",
  versionInfo: "/api/",
  dashboardPageHandle: "hotel/list",
  dashboardHotelSearchHandle: "hotel/search",
  hotelListPageHandle: "hotel/competitors",
  editHotelPostHandler: "hotel/edit",
  deleteHotelHandler: "hotel",
  getHotelDataHandler: "hotel/",
  getHotelReportHandler: "hotel/report",
  getScrapeHandler: "scrape/",
  competitorsList: "competitor/list",
  login: "user/authenticate",
  rolesList: "user/roles",
  addUser: "user/add",
  editUser: "user/edit/",
  userDetails: "user/detail",
  userList: "user/list",
  addCompetitor: "competitor/add",
  setAlgo: "hotel/set-algo-attributes",
  updateAlgo: "hotel/update-algo-attributes",
  addHotel: "hotel/add",
  deleteUser: "user/delete/",
  updateAIPrice: "hotel/update-ai-price/",
  uploadOccupancy: "hotel/occupancy/upload",
  reviewFormList: "hotel/review-list",
  addReveiwForm: "hotel/add-review",
  updateReviewForm: "hotel/update-review",
  getReviewById: "hotel/review",
  deleteReviewById: "hotel/review",
  addEvent: "hotel/add-event",
  getHotelEvents: "hotel/events",
  deleteEvent: "hotel/event",
  aiPriceGenerator: "hotel/run-ai-script",
  pushAiPrice: "hotel/push-ai-price",
  singleDateScrape: "scrape"
};

export const ENV_CONFIG = config[process.env.NODE_ENV || "development"];
