import { fetchWebApi } from '../webapis/apiResource'
import {
  SESSION_TERMINATED,
  STORE_TOKEN,
  LOGOUT_SUCCESS,
  SAVE_USER_DETAILS
} from '../constants/action.constant'
import { getAccessToken } from '../util/storeHelper'
import { BEConfig } from '../config/env'
import { handleFetchError } from '../util/errorHandler'
import { setUserStorage, removeUserStorage } from '../util/browserStorage'
import { showLoadingIndicator, hideLoadingIndicator } from '../actions/common.action'
export const setToken = ({ token }) => {
  return dispatch => {
    dispatch(tokenGenerator(token))
    setUserStorage(token, document.domain)
  }
}

export const setUserInfo = ({ userProfile }) => ({ type: SAVE_USER_DETAILS, userProfile })

export const fetchUserAndLoadData = () => {
  const { customerApi: { baseURL, protocol, port, versionInfo, userHandle } } = BEConfig
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${userHandle}`
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator())
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.status === 200 && response.data.success) {
          dispatch(setUserInfo(response.data))
        } else {
          if (response.status === 401) {
            dispatch(logOut())
          }
        }
        dispatch(hideLoadingIndicator())
        return response
      })
      .catch(error => {
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const logOut = (history, doNotTrackPrevHistory) => {
  removeUserStorage(document.domain)
  return (dispatch, getState) => {
    dispatch(logOutUser())
  }
}

export const tokenGenerator = (token) => ({ type: STORE_TOKEN, token })

export const terminateSession = () => ({ type: SESSION_TERMINATED })

export const logOutUser = () => ({ type: LOGOUT_SUCCESS })
