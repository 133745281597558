export const eventCardStying = {
  evenIndexStyle: {
    borderLeft: "5px solid #1593FF",
    backgroundColor: "#D4EBFF",
  },
  oddIndexStyle: {
    borderLeft: "5px solid #25D5B1",
    backgroundColor: "#D7FFF7",
  },
};
