import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./index.scss";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Box, TextField, Typography } from "@material-ui/core";

import QRCode from "react-qr-code";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHotelSearchResults } from "../../actions/dashboard.action";
import {
  createReviewForm,
  fetchReviewFormByHotelId,
  updateReviewForm,
} from "../../actions/reviewForm.action";
import { BEConfig } from "../../config/env";

import html2canvas from "html2canvas";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
} from "../../actions/common.action";

import { useFormik } from "formik";
import * as Yup from "yup";

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function feedbackApplicationLinkGenerator(hotelId) {
  return `${BEConfig.feedbackApplicationBaseURL}/${hotelId}`;
}

function isValidEmail(email) {
  return EMAIL_REGEX.test(email);
}

function validateEmails(feedbackEmails) {
  let isEmailsValid = true;
  const multipleEmails = feedbackEmails?.split(",");

  if (!multipleEmails?.length) return false;

  multipleEmails.forEach((email) => {
    if (!isValidEmail(email)) {
      isEmailsValid = false;
    }
  });

  return isEmailsValid;
}

const CreateReviewForm = (props) => {
  const [value, setValue] = React.useState(0);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [hotelDetails, setHotelDetails] = React.useState(false);
  const [hotelError, setHotelError] = React.useState(false);

  const [initialValues, setInitialValues] = React.useState({
    bookingUrl: "",
    googleUrl: "",
    expediaUrl: "",
    tripadvisorUrl: "",
    feedbackEmail: "",
  });

  const exportRef = React.useRef();
  const { hotelId } = useParams();

  useEffect(() => {
    if (hotelId) {
      props.fetchReviewFormByHotelId(hotelId, true);
    } else {
      loadSearchData("a");
    }
  }, [hotelId]);

  const validateSchema = Yup.object().shape({
    bookingUrl: Yup.string()
      .url("Please enter a valid url")
      .optional("This field is required"),
    googleUrl: Yup.string().url("Please enter a valid url").notRequired(),
    expediaUrl: Yup.string().url("Please enter a valid url").notRequired(),
    tripadvisorUrl: Yup.string().url("Please enter a valid url").notRequired(),
    feedbackEmail: Yup.string("Please enter a valid email").test(
      "is-tea",
      "Please Enter Valid Emails(Emails should be , separated and in correct format)",
      (value) => validateEmails(value)
    ),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: (values, {}) => {
      onHandleSave(values);
    },
  });

  const exportAsImage = async (element, imageFileName) => {
    props.showLoadingIndicator();

    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;

    const newWidth = element.scrollWidth - element.clientWidth;

    if (newWidth > element.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }

    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";

    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
    html.style.width = null;
    body.style.width = null;

    props.hideLoadingIndicator();
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  useEffect(() => {
    if (props.reviewFormDataByHotelId && hotelId) {
      const {
        hotelID,
        name,
        website,
        phoneNumber,
        address,
        feedbackEmail,
        bookingUrl,
        tripadvisorUrl,
        googleUrl,
        expediaUrl,
      } = props.reviewFormDataByHotelId;

      setInitialValues({
        feedbackEmail,
        bookingUrl,
        tripadvisorUrl,
        googleUrl,
        expediaUrl,
      });

      setHotelDetails({
        id: hotelID,
        name: name,
        website,
        phoneNumber,
        address,
      });
    }
  }, [props.reviewFormDataByHotelId, hotelId]);

  const handleTabChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const onSearchSelected = (_, hotel) => {
    setHotelDetails(hotel);

    if (!hotel) {
      setHotelError(true);
    } else {
      setHotelError(false);
    }
  };

  const loadSearchData = (value = "") => {
    props.fetchHotelSearchResults(value, false);
  };

  const onHandleSave = (values) => {
    // debugger;
    if (!hotelDetails) {
      setHotelError(true);
      return;
    } else {
      setHotelError(false);
    }

    const { feedbackEmail, bookingUrl, tripadvisorUrl, googleUrl, expediaUrl } =
      values;

    const payload = {
      HotelID: hotelDetails.id,
      FeedbackEmail: feedbackEmail,
      BookingUrl: bookingUrl,
      TripadvisorUrl: tripadvisorUrl,
      GoogleUrl: googleUrl,
      ExpediaUrl: expediaUrl,
    };

    if (hotelId) {
      props.updateReviewForm(payload).then((_) => {
        props.fetchReviewFormByHotelId(hotelId);
      });
    } else {
      props.createReviewForm(payload).then((_) => {
        props.history.push(`/review-form/edit/${hotelDetails.id}`);
      });
    }
  };

  const onHandleCancel = () => {
    props.history.push("/review-form/list");
  };

  const handleSubmit = (event) => {
    if (!hotelDetails) {
      setHotelError(true);
    } else {
      setHotelError(false);
    }

    formik.handleSubmit(event);
  };

  return (
    <Paper className="create-review-form-container">
      <Snackbar
        open={openSnackBar}
        autoHideDuration={1000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {openSnackBar}
        </Alert>
      </Snackbar>

      <div className="main-container">
        <div className="flex-1 col-12 p-0">
          <div className="col-8 p-0">
            <Breadcrumbs aria-label="breadcrumb" className="pb-5">
              <Link color="inherit" href="/review-form/list">
                Review Form
              </Link>
              <Link color="textPrimary" aria-current="page">
                Create Form
              </Link>
            </Breadcrumbs>

            <div className="py-4">
              {hotelId ? (
                <Typography variant="h4">{hotelDetails?.name}</Typography>
              ) : (
                <Autocomplete
                  id="combo-box-demo"
                  onChange={onSearchSelected}
                  options={props.hotelSearchResults}
                  getOptionLabel={(option) => option?.name}
                  style={{ width: "100%", height: 40 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Hotel Name"
                      variant="outlined"
                      size="small"
                      onChange={(e) => loadSearchData(e.target.value)}
                      error={hotelError}
                      helperText={hotelError && "This field is required!"}
                    />
                  )}
                />
              )}
            </div>

            <img src="/images/bookingcom.svg" alt="booking.com" />
            <div className="py-4">
              <TextField
                label="Enter Booking.com URL"
                variant="outlined"
                size="small"
                fullWidth
                name="bookingUrl"
                onChange={formik.handleChange}
                value={formik.values.bookingUrl}
                error={Boolean(
                  formik.touched.bookingUrl && formik.errors.bookingUrl
                )}
                helperText={
                  formik.touched.bookingUrl && formik.errors.bookingUrl
                }
              />
            </div>

            <img src="/images/tripadvisor.svg" alt="booking.com" />
            <div className="py-4">
              <TextField
                label="Enter Tripadvisor URL"
                variant="outlined"
                size="small"
                fullWidth
                name="tripadvisorUrl"
                onChange={formik.handleChange}
                value={formik.values.tripadvisorUrl}
                error={Boolean(
                  formik.touched.tripadvisorUrl && formik.errors.tripadvisorUrl
                )}
                helperText={
                  formik.touched.tripadvisorUrl && formik.errors.tripadvisorUrl
                }
              />
            </div>

            <img src="/images/google.svg" alt="google.com" />
            <div className="py-4">
              <TextField
                label="Enter Google URL"
                variant="outlined"
                size="small"
                fullWidth
                name="googleUrl"
                onChange={formik.handleChange}
                value={formik.values.googleUrl}
                error={Boolean(
                  formik.touched.googleUrl && formik.errors.googleUrl
                )}
                helperText={formik.touched.googleUrl && formik.errors.googleUrl}
              />
            </div>

            <img src="/images/expedia.svg" alt="expedia.com" />
            <div className="py-4">
              <TextField
                label="Enter Expedia URL"
                variant="outlined"
                size="small"
                fullWidth
                name="expediaUrl"
                onChange={formik.handleChange}
                value={formik.values.expediaUrl}
                error={Boolean(
                  formik.touched.expediaUrl && formik.errors.expediaUrl
                )}
                helperText={
                  formik.touched.expediaUrl && formik.errors.expediaUrl
                }
              />
            </div>

            <div className="py-4">
              <TextField
                label="Receive Feedback Email"
                variant="outlined"
                size="small"
                fullWidth
                name="feedbackEmail"
                onChange={formik.handleChange}
                value={formik.values.feedbackEmail}
                error={Boolean(
                  formik.touched.feedbackEmail && formik.errors.feedbackEmail
                )}
                helperText={
                  formik.touched.feedbackEmail && formik.errors.feedbackEmail
                }
                autoComplete="off"
              />
            </div>

            <div className="d-flex justify-content-between">
              <button className="primary-btn" onClick={handleSubmit}>
                Save
              </button>
              <button className="secondary-btn" onClick={onHandleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <Tabs
            value={value}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Template 1" />
            <Tab label="Template 2" />
          </Tabs>

          <TabPanel value={value} index={0}>
            {value === 0 && (
              <div className="template-container" ref={exportRef}>
                {hotelDetails ? (
                  <Template1 hotelDetails={hotelDetails} />
                ) : (
                  <DefaultTemplate />
                )}
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {value === 1 && (
              <div className="template-container" ref={exportRef}>
                {hotelDetails ? (
                  <Template2 hotelDetails={hotelDetails} />
                ) : (
                  <DefaultTemplate />
                )}
              </div>
            )}
          </TabPanel>

          <div className="w-50 m-auto">
            {hotelDetails && (
              <div
                className="d-flex justify-content-between"
                style={{ gap: "20px" }}
              >
                <CopyToClipboard
                  text={feedbackApplicationLinkGenerator(hotelDetails.id)}
                  onCopy={() => setOpenSnackBar("Url Copied")}
                >
                  <button className="secondary-btn">Copy QR Code</button>
                </CopyToClipboard>
                <button
                  className="primary-btn"
                  onClick={() =>
                    exportAsImage(
                      exportRef.current,
                      `${hotelDetails?.name} QR Code`
                    )
                  }
                >
                  Download
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
};

const DefaultTemplate = () => {
  return (
    <div className="default-template">
      <Typography>
        Please Select the Form <br /> to Generate Hotel Template
      </Typography>
    </div>
  );
};

const Template1 = ({ hotelDetails, exportRef }) => {
  return (
    <>
      <div className="template-1-container d-flex justify-content-center">
        <div className="d-flex justify-content-center flex-column">
          <Typography style={{ fontSize: "18px" }}>Welcome</Typography>
          <Typography style={{ fontSize: "18px", fontWeight: 600 }}>
            {hotelDetails.name}
          </Typography>
        </div>
      </div>

      <div className="px-4 text-center">
        <Typography
          style={{ fontSize: "24px", fontWeight: "bold" }}
          className="pt-4 pb-3"
          variant="h5"
        >
          Share Your Feedback With Us
        </Typography>

        <Typography style={{ fontSize: "16px" }} className="px-5">
          Scan the QR code below and share your thoughts about your experience
        </Typography>

        <QrContainer hotelId={hotelDetails.id} />

        <div className="pb-3">
          <HotelContactInfo hotelDetails={hotelDetails} />

          <HotelAddress hotelDetails={hotelDetails} />
        </div>
      </div>
    </>
  );
};

const Template2 = ({ hotelDetails }) => {
  return (
    <>
      <div className="template-2-inner-container">
        <Typography style={{ fontSize: "30px", paddingTop: "26px" }}>
          HOW DID WE <span style={{ fontWeight: "bold" }}>DO?</span>
        </Typography>

        <QrContainer paddingTop={"25px"} hotelId={hotelDetails.id} />

        <Typography style={{ fontSize: "22px" }}>
          We'd Love To <span style={{ fontWeight: "bold" }}>KNOW</span>
        </Typography>

        <Typography style={{ fontSize: "16px", padding: "10px 0 19px 0" }}>
          Scan the QR Code and it will open a webpage where you can submit your
          review.
        </Typography>
      </div>

      <div className="template-2-highlighter-container">
        {/* <div style={{ textAlign: "center", margin: "auto", padding: "20px" }}> */}
        <Typography style={{ fontSize: "18px", fontWeight: 600 }}>
          {hotelDetails.name}
        </Typography>
        {/* </div> */}

        {(hotelDetails.website ||
          hotelDetails.phoneNumber ||
          hotelDetails.address) && (
          <div>
            <div className="pb-1">
              <HotelContactInfo hotelDetails={hotelDetails} />
            </div>

            <HotelAddress hotelDetails={hotelDetails} />
          </div>
        )}
      </div>
    </>
  );
};

const QrContainer = ({ paddingTop, hotelId }) => {
  return (
    <div
      style={{
        height: "210px",
        width: "150px",
        margin: "auto",
        paddingTop: paddingTop ?? "35px",
      }}
    >
      <QRCode
        size={150}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={feedbackApplicationLinkGenerator(hotelId)}
        viewBox={`0 0 150 150`}
        id="qrcode"
      />
    </div>
  );
};

const HotelContactInfo = ({ hotelDetails }) => {
  return (
    <div className="hotel-address-container">
      <p>
        {hotelDetails.website}&nbsp;&nbsp;
        {hotelDetails.website && hotelDetails.phoneNumber && "|"}&nbsp;&nbsp;
        {hotelDetails.phoneNumber}
      </p>
    </div>
  );
};

const HotelAddress = ({ hotelDetails }) => {
  return (
    <Typography style={{ fontSize: "12px" }}>{hotelDetails.address}</Typography>
  );
};

const mapStateToProps = (state) => ({
  hotelSearchResults: state.dashboardReducer.hotelSearchResults,
  reviewFormDataByHotelId: state.reviewFormReducer.reviewFormDataByHotelId,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHotelSearchResults: bindActionCreators(
    fetchHotelSearchResults,
    dispatch
  ),
  createReviewForm: bindActionCreators(createReviewForm, dispatch),
  updateReviewForm: bindActionCreators(updateReviewForm, dispatch),
  fetchReviewFormByHotelId: bindActionCreators(
    fetchReviewFormByHotelId,
    dispatch
  ),
  showLoadingIndicator: bindActionCreators(showLoadingIndicator, dispatch),
  hideLoadingIndicator: bindActionCreators(hideLoadingIndicator, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateReviewForm);
