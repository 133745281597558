import React, {Component} from 'react';


class AIPriceTitle extends Component{
    render(){
        return (
          <div className="AIprice-title">
            <p>
              <img src="/images/AIPrice.svg" alt="aiprice" />
              AI Recommended Price
            </p>
            <div className="AI-button-wrapper">
              <button className="cancel">Cancel</button>
              <button className="price">Save Price</button>
            </div>
          </div>
        );
    }
}

export default AIPriceTitle;