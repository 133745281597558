export const BROWSER_STORAGE = {
  ACCESS_TOKEN: 'access_token',
  USER_COUNTRY: 'user_country',
  USER_COUNTRY_SELECTION: 'user_country_selection',
  USER_STORE: 'user_store',
  PREV_MODE: 'prev_mode',
  COOKIE_EXPIRY_TIME: 60 * 60 * 23
}

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'
export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA'
export const GET_HOTEL_SEARCH_DATA = 'GET_HOTEL_SEARCH_DATA'
export const GET_HOTEL_LIST_DATA = 'GET_HOTEL_LIST_DATA'
export const GET_HOTEL_DATA = 'GET_HOTEL_DATA'
export const GET_COMPETITORS_LIST = 'GET_COMPETITORS_LIST'
export const GET_AI_PRICE_GENERTOR = 'GET_AI_PRICE_GENERTOR'

export const SHOW_ERROR_PAGE = 'SHOW_ERROR_PAGE'
export const HIDE_ERROR_PAGE = 'HIDE_ERROR_PAGE'
export const ERROR_NOT_FOUND = 'ERROR_NOT_FOUND'

export const SESSION_TERMINATED = 'SESSION_TERMINATED'
export const STORE_TOKEN = 'STORE_TOKEN'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS'
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA'
export const SAVE_USER_DATA = 'SAVE_USER_DATA'
export const USER_LIST_DATA = 'USER_LIST_DATA'
export const ROLE_LIST_DATA = 'ROLE_LIST_DATA'

// Snackbar
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

// Overlay
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const SHOW_OVERLAY = 'SHOW_OVERLAY'
export const HIDE_OVERLAY = 'HIDE_OVERLAY'

// Common Reducer
export const SET_MODULE_NAME = 'SET_MODULE_NAME'

// HOTEL REVIEW FORM
export const GET_REVIEW_FORM_LIST_DATA = 'GET_REVIEW_FORM_LIST_DATA'
export const GET_REVIEW_FORM_DATA_BY_HOTEL_ID = 'GET_REVIEW_FORM_DATA_BY_HOTEL_ID'

