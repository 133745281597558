import React, { useState, useEffect, useCallback } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Dialog from "@material-ui/core/Dialog";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ClearIcon from "@material-ui/icons/Clear";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import "./eventsCalendar.scss";
import { fetchHotelEvents } from "../../../../../actions/events.action";

const EventsCalendarDialog = ({
  handleClose,
  eventStartDate: eventStartDateFromProps,
  fetchHotelEvents,
  hotelId,
}) => {
  const [hotelEvents, setHotelEvents] = useState([]);
  const [eventStartDate, setEventStartDate] = useState(eventStartDateFromProps);

  const getHotelEvents = useCallback(async () => {
    const startDate = moment(eventStartDate)
      .subtract(1, "month")
      .startOf("month")
      .format("l");
    const endDate = moment(eventStartDate)
      .add(1, "month")
      .endOf("month")
      .format("l");

    const { events } = await fetchHotelEvents(
      { startDate, endDate, hotelId },
      true
    );

    const updatedEvents = events.map((event) => ({
      ...event,
      id: event.eventID,
      title: event.eventName,
      allDay: true,
    }));

    setHotelEvents(updatedEvents);
  }, [eventStartDate, fetchHotelEvents, hotelId]);

  useEffect(() => {
    getHotelEvents();
  }, [eventStartDate, getHotelEvents]);

  const localizer = momentLocalizer(moment); // or globalizeLocalizer

  const handleDateChange = (date, _) => {
    setEventStartDate(new Date(date));
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };

    const goToToday = () => {
      toolbar.onNavigate("TODAY");
    };

    return (
      <div className="rbc-toolbar">
        <span
          className="rbc-btn-group"
          style={{ display: "flex", alignItems: "center" }}
        >
          <button className="calendar-nav-btn" onClick={goToBack}>
            <ChevronLeftIcon style={{ height: "40px", width: "40px" }} />
          </button>
          <span className="rbc-toolbar-label">{toolbar.label}</span>
          <button className="calendar-nav-btn" onClick={goToNext}>
            <ChevronRightIcon style={{ height: "40px", width: "40px" }} />
          </button>
        </span>
      </div>
    );
  };

  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open
      fullWidth
    >
      <div className="text-right pt-3 pr-3" style={{ cursor: "pointer" }}>
        <ClearIcon onClick={handleClose} />
      </div>

      <div
        style={{ width: "100%", height: "800px" }}
        className="events-calendar-dialog-container"
      >
        <Calendar
          localizer={localizer}
          events={hotelEvents}
          startAccessor="eventDate"
          endAccessor="eventDate"
          popup
          views={["month"]}
          defaultDate={eventStartDate}
          onNavigate={handleDateChange}
          components={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchHotelEvents: bindActionCreators(fetchHotelEvents, dispatch),
});

export default connect(null, mapDispatchToProps)(EventsCalendarDialog);
