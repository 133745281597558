import React, { Fragment, useState, useEffect } from 'react';
import Modal from '../../compenents/modal/modal';
import { Grid, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAlgo } from '../../actions/hotelList.action';
import { fetchHotelData } from '../../actions/editHotel.action';
import styles from './style.scss';

function HotelSettings(props) {
	const initialFormValues = {
		hotelQuality: '',
		rateDifferenceWeekday: '',
		rateDifferenceWeekend: '',
		bookingCurves: [
			{
				min: '',
				max: '',
				expectedBooking: '',
			},
			{
				min: '',
				max: '',
				expectedBooking: '',
			},
			{
				min: '',
				max: '',
				expectedBooking: '',
			},
			{
				min: '',
				max: '',
				expectedBooking: '',
			},
			{
				min: '',
				max: '',
				expectedBooking: '',
			},
		],
		competitorQualities: [],
	};
	const { dialogClose, open, hotel } = props;
	const [form, setForm] = useState(initialFormValues);
	const [errors, setErrors] = useState({
		bookingCurves: { 0: {}, 1: {}, 2: {}, 3: {}, 4: {} },
		competitorQualities: {},
	});
	const [touched, setToutched] = useState({
		bookingCurves: {},
		competitorQualities: {},
	});

	const [isNew, setIsNew] = useState(true);

	useEffect(() => {
		if (hotel) {
			props.fetchHotelData(hotel.id);
			setToutched({
				bookingCurves: {},
				competitorQualities: {},
			});
			setForm({
				...initialFormValues,
				competitorQualities: hotel.competitors.map((v) => ({
					id: v.id,
					name: v.name,
					quality: '',
				})),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hotel]);

	useEffect(() => {
		if (props.hotelData?.data) {
			if (props.hotelData.data.algoAttributes) {
				setIsNew(false);
				const algoAttributes = props.hotelData.data.algoAttributes;
				setForm({
					hotelQuality: algoAttributes.hotel_Quality,
					rateDifferenceWeekday: algoAttributes.rate_Difference_Weekday,
					rateDifferenceWeekend: algoAttributes.rate_Difference_Weekend,
					bookingCurves: form.bookingCurves.map((_, k) => {
						let min = '';
						let max = '';
						if (typeof algoAttributes.booking_Curve[k].doa == 'string') {
							min = algoAttributes.booking_Curve[k].doa.split('-')[0] || '';
							max = algoAttributes.booking_Curve[k].doa.split('-')[1] || '';
						}
						return {
							min,
							max,
							expectedBooking:
								algoAttributes.booking_Curve[k]?.expected_Booking,
						};
					}),
					competitorQualities: form.competitorQualities.map((v, k) => ({
						...v,
						quality:
							algoAttributes.competitor_Quality.find(
								(v1) => v1.competitor_ID === v.id
							)?.quality || '',
					})),
				});
			} else {
				setIsNew(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.hotelData]);

	const handleBcChange = (k, type, value) => {
		const temp = { ...form };
		temp.bookingCurves[k][type] = value;
		setForm(temp);
		setToutched({
			...touched,
			bookingCurves: {
				...touched.bookingCurves,
				[k]: {
					...(touched.bookingCurves[k] || {}),
					[type]: true,
				},
			},
		});
	};

	const handleCqChange = (k, value) => {
		const temp = { ...form };
		temp.competitorQualities[k].quality = value;
		setForm(temp);
		setToutched({
			...touched,
			competitorQualities: {
				...touched.competitorQualities,
				[k]: true,
			},
		});
	};

	const validateForm = () => {
		const errs = {
			bookingCurves: { 0: {}, 1: {}, 2: {}, 3: {}, 4: {} },
			competitorQualities: {},
		};
		if (isNaN(parseFloat(form.hotelQuality))) {
			errs['hotelQuality'] = 'Field is required';
		} else {
			if (
				parseFloat(form.hotelQuality) < 1 ||
				parseFloat(form.hotelQuality) > 5
			) {
				errs['hotelQuality'] = 'Between 1.0 - 5.0 ';
			}
		}

		if (isNaN(parseFloat(form.rateDifferenceWeekday))) {
			errs['rateDifferenceWeekday'] = 'Field is required';
		} else {
			if (
				parseFloat(form.rateDifferenceWeekday) < 1 ||
				parseFloat(form.rateDifferenceWeekday) > 100
			) {
				errs['rateDifferenceWeekday'] = 'Between 1 - 100';
			}
		}

		if (isNaN(parseFloat(form.rateDifferenceWeekend))) {
			errs['rateDifferenceWeekend'] = 'Field is required';
		} else {
			if (
				parseFloat(form.rateDifferenceWeekend) < 1 ||
				parseFloat(form.rateDifferenceWeekend) > 100
			) {
				errs['rateDifferenceWeekend'] = 'Between 1 - 100';
			}
		}

		form.bookingCurves.forEach((bc, i) => {
			if (i < 4) {
				form.bookingCurves[i + 1].min = '';
			}
			let noCheck = false;
			if (isNaN(parseFloat(bc.min))) {
				errs['bookingCurves'][i]['min'] = 'Field is required';
				noCheck = true;
			}
			if (isNaN(parseFloat(bc.max))) {
				errs['bookingCurves'][i]['max'] = 'Field is required';
				noCheck = true;
			}
			if (isNaN(parseFloat(bc.expectedBooking))) {
				errs['bookingCurves'][i]['expectedBooking'] = 'Field is required';
			}
			if (noCheck) return;
			if (parseFloat(bc.min) >= parseFloat(bc.max)) {
				errs['bookingCurves'][i]['min'] = 'Invalid Range';
				errs['bookingCurves'][i]['max'] = 'Invalid Range';
				return;
			}
			if (i < 4) {
				form.bookingCurves[i + 1].min = parseFloat(bc.max) + 1;
			}
		});

		form.competitorQualities.forEach((cc, i) => {
			if (isNaN(parseFloat(cc.quality))) {
				errs['competitorQualities'][i] = 'Field is required';
			} else {
				if (parseFloat(cc.quality) < 1 || parseFloat(cc.quality) > 5) {
					errs['competitorQualities'][i] = 'Between 1.0 - 5.0';
				}
			}
		});
		setErrors(errs);
	};

	useEffect(() => {
		validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	const markAllAsTouched = () => {
		const touchs = {
			hotelQuality: true,
			rateDifferenceWeekday: true,
			rateDifferenceWeekend: true,
			bookingCurves: {},
			competitorQualities: {},
		};
		form.competitorQualities.forEach((_, i) => {
			touchs.competitorQualities[i] = true;
		});
		new Array(5).fill(0).forEach((_, k) => {
			touchs.bookingCurves[k] = {
				min: true,
				max: true,
				expectedBooking: true,
			};
		});
		setToutched(touchs);
	};

	const isValid = () => {
		return (
			Object.keys(errors).length === 2 &&
			Object.keys(errors.bookingCurves['0']).length === 0 &&
			Object.keys(errors.bookingCurves['1']).length === 0 &&
			Object.keys(errors.bookingCurves['2']).length === 0 &&
			Object.keys(errors.bookingCurves['3']).length === 0 &&
			Object.keys(errors.bookingCurves['4']).length === 0
		);
	};

	const submitHandler = () => {
		markAllAsTouched();
		if (!isValid()) return;

		const body = {
			Hotel_ID: hotel.id,
			Hotel_Quality: parseFloat(form.hotelQuality),
			Rate_Difference_Weekday: parseFloat(form.rateDifferenceWeekday),
			Rate_Difference_Weekend: parseFloat(form.rateDifferenceWeekend),
			Booking_Curve: form.bookingCurves.map((v) => ({
				Expected_Booking: parseFloat(v.expectedBooking),
				DOA: `${parseFloat(v.min)}-${parseFloat(v.max)}`,
			})),
			Competitor_Quality: form.competitorQualities.map((v) => ({
				Competitor_ID: v.id,
				Quality: parseFloat(v.quality),
			})),
		};

		props.setAlgo(body, isNew).then(() => {
			setForm(initialFormValues);
			props.dialogClose();
		});
	};

	return (
		<Modal
			open={open}
			onClose={dialogClose}
			class="md"
			fullWidth="true"
			modalHeading=""
			modalSaveTxt="Save"
			modalCloseTxt="Cancel"
			onClick={submitHandler}
			showActionBtn
			savebtnClassName="createBtn"
			primary
			className={styles.algoModal}
		>
			<div className="popup">
				<div className="container">
					<div className="row">
						<div class="col-12">
							<h1 class="heading">{hotel?.name}</h1>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="quality">
								<div className="text-section">
									<h2>Hotel Product Quality</h2>
									<span>(Between 1.0 - 5.0)</span>
								</div>

								<div
									className={`input-number${
										touched.hotelQuality && !!errors['hotelQuality']
											? ' error'
											: ''
									}`}
								>
									<input
										type="number"
										value={form.hotelQuality}
										onChange={(e) => {
											setForm({ ...form, hotelQuality: e.target.value });
											setToutched({ ...touched, hotelQuality: true });
										}}
									/>
									<p className="err-msg">
										{touched.hotelQuality && errors['hotelQuality']}
									</p>
								</div>
							</div>
						</div>

						<div className="col-lg-6 col-md-6"></div>
						<div className="col-lg-6 col-md-6">
							<div className="quality">
								<div className="text-section">
									<h2>Rate Differential Weekday</h2>
									<span>
										The difference in rates
										<br />
										and also conditions
									</span>
								</div>

								<div
									className={`input-number1${
										touched.rateDifferenceWeekday &&
										!!errors['rateDifferenceWeekday']
											? ' error'
											: ''
									}`}
								>
									<input
										type="number"
										name="rate"
										value={form.rateDifferenceWeekday}
										onChange={(e) => {
											setForm({
												...form,
												rateDifferenceWeekday: e.target.value,
											});
											setToutched({ ...touched, rateDifferenceWeekday: true });
										}}
									/>
									<span>$</span>
									<p className="err-msg">
										{touched.rateDifferenceWeekday &&
											errors['rateDifferenceWeekday']}
									</p>
								</div>
							</div>
						</div>

						<div className="col-lg-6 col-md-6">
							<div className="quality">
								<div className="text-section">
									<h2>Rate Differential Weekend</h2>
									<span>
										The difference in rates
										<br />
										and also conditions
									</span>
								</div>

								<div
									className={`input-number1${
										touched.rateDifferenceWeekend &&
										!!errors['rateDifferenceWeekend']
											? ' error'
											: ''
									}`}
								>
									<input
										type="number"
										name="rate"
										value={form.rateDifferenceWeekend}
										onChange={(e) => {
											setForm({
												...form,
												rateDifferenceWeekend: e.target.value,
											});
											setToutched({ ...touched, rateDifferenceWeekend: true });
										}}
									/>
									<span>$</span>
									<p className="err-msg">
										{touched.rateDifferenceWeekend &&
											errors['rateDifferenceWeekend']}
									</p>
								</div>
							</div>
						</div>

						<div className="col-lg-6 col-md-6">
							<div className="quality">
								<div className="text-section">
									<h2>Booking Curve</h2>
									<span>
										Date of Arrival Ranges to be defined
										<br />
										upto 30 days max
									</span>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-3 col-md-2 col-3"></div>

						<div className="col-lg-4 col-md-5 col-5 align-self-center">
							<div className="d-flex">
								<div className="sort-heading">
									<p className="font-weight-500">From</p>
								</div>

								<div className="sort-heading2">
									<p className="font-weight-500">To</p>
								</div>
							</div>
						</div>

						<div className="col-md-5 col-4">
							<div className="input-number5">
								<p className="font-weight-500">Expected Occupancy</p>
							</div>
						</div>
					</div>

					<div className="row">
						{form.bookingCurves.map((_, k) => (
							<React.Fragment key={k}>
								<div className="col-lg-3 col-md-2 col-3">
									<h3 className="set">DOA Set {k + 1}</h3>
								</div>

								<div className="col-lg-4 col-md-5 col-5">
									<div className="d-flex">
										<div
											className={`input-number2${
												touched.bookingCurves[k]?.min &&
												!!errors['bookingCurves'][k]?.min
													? ' error'
													: ''
											}`}
										>
											<input
												type="number"
												value={form.bookingCurves[k].min}
												onChange={(e) =>
													handleBcChange(k, 'min', e.target.value)
												}
												disabled={k > 0}
											/>
											<p className="err-msg text-center">
												{touched.bookingCurves[k]?.min &&
													errors['bookingCurves'][k]?.min}
											</p>
										</div>

										<div className="middle">
											<span>-</span>
										</div>
										<div
											className={`input-number2${
												touched.bookingCurves[k]?.max &&
												!!errors['bookingCurves'][k]?.max
													? ' error'
													: ''
											}`}
										>
											<input
												type="number"
												value={form.bookingCurves[k].max}
												onChange={(e) =>
													handleBcChange(k, 'max', e.target.value)
												}
											/>
											<p className="err-msg text-center">
												{touched.bookingCurves[k]?.max &&
													errors['bookingCurves'][k]?.max}
											</p>
										</div>
									</div>
								</div>

								<div className="col-md-5 col-4">
									<div
										className={`input-number3 w-100${
											touched.bookingCurves[k]?.expectedBooking &&
											!!errors['bookingCurves'][k]?.expectedBooking
												? ' error'
												: ''
										}`}
									>
										<input
											type="number"
											value={form.bookingCurves[k].expectedBooking}
											onChange={(e) =>
												handleBcChange(k, 'expectedBooking', e.target.value)
											}
										/>
										<span>%</span>
										<p className="err-msg">
											{touched.bookingCurves[k]?.expectedBooking &&
												errors['bookingCurves'][k]?.expectedBooking}
										</p>
									</div>
								</div>
							</React.Fragment>
						))}
					</div>

					{!!form.competitorQualities.length && (
						<div className="row mt-2">
							<div className="col-lg-4 col-md-6">
								<div className="quality">
									<div className="text-section">
										<h2>Competitor's Product Quality</h2>
										<span>(Between 1.0 - 5.0 relative to the Hotel)</span>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="row mt-2">
						{form.competitorQualities.map((v, k) => (
							<div className="col-md-6" key={v.id}>
								<div className="quality">
									<div className="text-section w-70">
										<h2>{v.name}</h2>
									</div>

									<div
										className={`input-number w-30 ${
											touched.competitorQualities[k] &&
											!!errors['competitorQualities'][k]
												? ' error'
												: ''
										}`}
									>
										<input
											type="number"
											value={form.competitorQualities[k].quality}
											onChange={(e) => handleCqChange(k, e.target.value)}
										/>
										<p className="err-msg">
											{touched.competitorQualities[k] &&
												errors['competitorQualities'][k]}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	user: state.user.userData,
	hotelData: state.editHotelReducer,
});
const mapDispatchToProps = (dispatch) => ({
	setAlgo: bindActionCreators(setAlgo, dispatch),
	fetchHotelData: bindActionCreators(fetchHotelData, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(HotelSettings);
