import { BEConfig } from '../config/env'
import { fetchWebApi, putDashboardWebApi, postAuthWebApi, deleteWebApi } from '../webapis/apiResource'
import { getAccessToken } from '../util/auth'
import { showLoadingIndicator, hideLoadingIndicator, successSnackBar, errorSnackBar } from '../actions/common.action'
import { handleFetchError } from '../util/errorHandler'
import { GET_COMPETITORS_LIST, GET_HOTEL_DATA } from '../constants/action.constant'
import store from '../store'

export const updateHotelEditData = (hotelId, hotelData, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, getHotelDataHandler } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${getHotelDataHandler}${hotelId}/edit`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return putDashboardWebApi(getAccessToken(getState), apiURL, hotelData)
      .then(response => {
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const refreshHotelPrice = (hotelId, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, getScrapeHandler } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${getScrapeHandler}${hotelId}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('Hotel Price Fetching Request Submitted, Please Refresh After Sometime.', {autoHideDuration: 6000 }))
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const updateHotelStatus = (hotelId, status, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, getHotelDataHandler } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${getHotelDataHandler}${hotelId}/status`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return putDashboardWebApi(getAccessToken(getState), apiURL, status)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('Hotel Status updated Succesfully'))
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const getHotelData = data => ({ type: GET_HOTEL_DATA, data })
export const getCompetitorsList = data => ({ type: GET_COMPETITORS_LIST, data })

export const fetchHotelData = (hotelId, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, getHotelDataHandler } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${getHotelDataHandler}${hotelId}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (showLoader) {
          dispatch(hideLoadingIndicator())
        }
        if (response.data && response.data.id && response.data.id === hotelId) {
          dispatch(getHotelData(response.data))
        }
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const fetchCompetitorsList = (showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, competitorsList } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${competitorsList}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (showLoader) {
          dispatch(hideLoadingIndicator())
        }
        if (response.data && response.data.length) {
          dispatch(getCompetitorsList(response.data))
        }
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const uploadOccupancy = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, uploadOccupancy } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${uploadOccupancy}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('Hotel Occupancy Uploaded Succesfully'))
        return response
      })
      .catch(error => {
        store.dispatch(errorSnackBar('Something went wrong, please try again.'))
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const deleteHotel = (id, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, deleteHotelHandler } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${deleteHotelHandler}/${id}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return deleteWebApi(getAccessToken(), apiURL)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('Hotel Deleted Succesfully'))
        return response
      })
      .catch(error => {
        store.dispatch(errorSnackBar('Something went wrong, please try again.'))
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}
