import React, { useEffect, useState } from "react";
import "./index.scss";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  deleteReviewForm,
  fetchReviewFormListData,
} from "../../actions/reviewForm.action";

import { BEConfig } from "../../config/env";
import { ConfirmationDialog } from "./confirm-dialog";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ReviewFormList = (props) => {
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [opneConfirmationDialog, setOpneConfirmationDialog] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState(null);

  useEffect(() => {
    props.fetchReviewFormListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateForm = () => {
    props.history.push("/review-form/create");
  };

  const handleEditForm = (hotelID) => {
    props.history.push(`/review-form/edit/${hotelID}`);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleOpenDialog = (id) => {
    setSelectedHotelId(id);
    setOpneConfirmationDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedHotelId(null);
    setOpneConfirmationDialog(false);
  };

  const handleConfirmDialog = async () => {
    await props.deleteReviewForm(selectedHotelId);
    props.fetchReviewFormListData();
    handleCloseDialog();
  };

  return (
    <div className="review-form-list-container">
      <div className="header-container">
        <p className="title">Review Form</p>
        <button className="primary-btn" onClick={handleCreateForm}>
          Create Review Form
        </button>
      </div>

      {opneConfirmationDialog && (
        <ConfirmationDialog
          open={opneConfirmationDialog}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmDialog}
          title="Confirmation"
          content="Are you sure you want to delete this review form?"
        />
      )}

      <Snackbar
        open={openSnackBar}
        autoHideDuration={1000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          Url Copied
        </Alert>
      </Snackbar>

      <TableContainer
        component={Paper}
        className="list-table-container"
        style={{ maxHeight: "75vh" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong style={{ fontSize: 16 }}>Hotel Name</strong>
              </TableCell>
              <TableCell>
                <strong style={{ fontSize: 16 }}>Booking.com Url</strong>
              </TableCell>
              <TableCell>
                <strong style={{ fontSize: 16 }}>Trip Advisor Url</strong>
              </TableCell>
              <TableCell>
                <strong style={{ fontSize: 16 }}>Google Url</strong>
              </TableCell>
              <TableCell>
                <strong style={{ fontSize: 16 }}>Expedia Url</strong>
              </TableCell>
              <TableCell style={{ width: "140px" }} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.reviewFormList?.map((row) => (
              <TableRow
                key={row.name}

                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{ fontSize: 16 }} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell className="ellipsis"  style={{ fontSize: 16 }}><span >{row.bookingUrl} </span></TableCell>
                <TableCell className="ellipsis" style={{ fontSize: 16 }}>
                  <span >
                    {row.tripadvisorUrl}
                  </span>
                </TableCell>
                <TableCell className="ellipsis" style={{ fontSize: 16 }}><span >{row.googleUrl}</span></TableCell>
                <TableCell className="ellipsis" style={{ fontSize: 16 }}><span >{row.expediaUrl}</span></TableCell>
                <TableCell
                  style={{ fontSize: 16, width: "140px" }}
                  align="right"
                >
                  <div className="list-icons">
                    <CopyToClipboard
                      text={`${BEConfig.feedbackApplicationBaseURL}/${row.hotelID}`}
                      onCopy={() => setOpenSnackBar(true)}
                    >
                      <FileCopyOutlinedIcon />
                    </CopyToClipboard>

                    <EditIcon onClick={() => handleEditForm(row.hotelID)} />

                    <DeleteIcon onClick={() => handleOpenDialog(row.hotelID)} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reviewFormList: state.reviewFormReducer.reviewFormList,
});
const mapDispatchToProps = (dispatch) => ({
  fetchReviewFormListData: bindActionCreators(
    fetchReviewFormListData,
    dispatch
  ),
  deleteReviewForm: bindActionCreators(deleteReviewForm, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReviewFormList);
