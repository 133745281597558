import { webapiGet, webapiPut, webapiPost, webapiAuthPost, webapiDelete, webapiGetWithParams, webapiDownload } from './core'

export function fetchWebApi (accessToken, url, defaultHeaders = false) {
  return webapiGet(accessToken, url, defaultHeaders).request
}
export function fetchDownloadApi (accessToken, url, defaultHeaders = false) {
  return webapiDownload(accessToken, url, defaultHeaders).request
}
export function fetchWebApiWithParam (accessToken, url, bodyObject) {
  return webapiGetWithParams(accessToken, url, bodyObject).request
}
export function putDashboardWebApi (accessToken, url, bodyObject) {
  return webapiPut(accessToken, url, bodyObject).request
}

export function postDashboardWebApi (accessToken, url, bodyObject, defaultHeaders = false) {
  return webapiPost(accessToken, url, bodyObject, defaultHeaders).request
}

export function postAuthWebApi (accessToken, url, bodyObject, defaultHeaders = false) {
  return webapiPost(accessToken, url, bodyObject, defaultHeaders).request
}

export function putAuthWebApi (accessToken, url, bodyObject, defaultHeaders = false) {
  return webapiPut(accessToken, url, bodyObject, defaultHeaders).request
}

export function postWebApi (accessToken, url, bodyObject, defaultHeaders = false) {
  return webapiPost(url, bodyObject, defaultHeaders).request
}

export function postAuthApi (url, bodyObject) {
  return webapiAuthPost(url, bodyObject).request
}

export function deleteWebApi (accessToken, url) {
  return webapiDelete(accessToken, url).request
}

export function postAddUserWebApi (accessToken, url, bodyObject, defaultHeaders = false) {
  return webapiPost(accessToken, url, bodyObject, defaultHeaders).request
}