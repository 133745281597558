import React, {Component} from 'react';

class Search extends Component{
    render(){
        return (
            <div className="search-container">
                <input type="text" placeholder= "Search here" />
                <a className="search_button"><i className={`material-icons search_btn`}>search</i></a>
                <a className="expand_more"><i className="material-icons expand_mre">expand_more</i></a>
            </div>
        )
    }
}

export default Search;