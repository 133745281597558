import React, { Component } from "react";
import AddUser from "./add-user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getRolesList,
  getUserList,
  deleteUser,
} from "../../actions/user-management.action";
import { fetchHotelListData } from "../../actions/hotelList.action";
import DeleteDialog from "../../compenents/delete-dialog";
import { InputAdornment, TextField } from "@material-ui/core";
import Search from "@material-ui/icons/Search";

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addUserDialogState: false,
      editUserDialogState: false,
      deleteDialogState: false,
      editableUser: {},
      searchString: "",
    };
    this.headerColumns = ["Last Name", "Role", "Email Id", "Status", "Actions"];
  }

  componentDidMount() {
    const { getRolesList, getUserList, fetchHotelListData } = this.props;
    getUserList();
    getRolesList();
    fetchHotelListData();
  }

  deleteHandler = (id) => {
    const { deleteUser, getUserList } = this.props;
    deleteUser(this.state.deleteId).then((res) => {
      this.setState({
        deleteDialogState: false,
      });
      getUserList();
    });
  };

  deleteDialogHandler = (id = "") => {
    this.setState((prevState) => ({
      deleteDialogState: !prevState.deleteDialogState,
      deleteId: id,
    }));
  };

  addUserHandler = (id = "") => {
    this.setState((prevState) => ({
      addUserDialogState: !prevState.addUserDialogState,
      editableUser: {},
    }));
  };

  editUserHandler = (user) => {
    this.setState((prevState) => ({
      addUserDialogState: !prevState.addUserDialogState,
      editUserDialogState: !prevState.editUserDialogState,
      editableUser: user,
    }));
  };

  closeHandler = () => {
    this.setState((prevState) => ({
      addUserDialogState: false,
      editUserDialogState: false,
      editableUser: {},
    }));
  };

  refreshUserList = () => {
    const { getUserList } = this.props;
    getUserList();
  };

  render() {
    const {
      addUserDialogState,
      editUserDialogState,
      editableUser,
      deleteDialogState,
      deleteId,
      searchString,
    } = this.state;
    const { userList } = this.props;

    return (
      <div className="user hotel-list-container">
        <div className="container">
          <div style={{ color: "red" }}>
            <DeleteDialog
              open={deleteDialogState}
              id={deleteId}
              deleteHandler={this.deleteHandler}
              dialogClose={this.deleteDialogHandler}
              moduleName="User"
            />
          </div>

          <div className="row">
            <div className="userManagement">
              <div className="headerLeft">
                <h3>User Management</h3>
              </div>
              <div className="headerRight">
                <div className="searchBox">
                  <TextField
                    label="Search by user name"
                    variant="outlined"
                    className="w-100"
                    onChange={(e) =>
                      this.setState({
                        searchString: e.target.value,
                      })
                    }
                    value={this.searchString}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="button-wrapper">
                  <button
                    className="btn add-competitor"
                    onClick={this.addUserHandler}
                  >
                    Add User
                  </button>
                </div>
              </div>
            </div>
            {addUserDialogState && (
              <AddUser
                open={addUserDialogState}
                dialogClose={this.closeHandler}
                roles={this.props.roles}
                hotels={this.props.hotelListData}
                existingUsers={userList.map((user) => user.email)}
                editMode={editUserDialogState}
                user={editableUser}
                refresh={this.refreshUserList}
              />
            )}
            <ul className={"list-header"}>
              <li>First Name</li>
              {this.headerColumns.map((item, index) => {
                return <li>{item}</li>;
              })}
            </ul>
            <ul className={"list-body"}>
              {userList
                ?.filter(
                  (u) =>
                    u?.firstName?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
                    u?.lastName?.toLowerCase()?.includes(searchString?.toLowerCase())
                )
                ?.map((user) => {
                  return (
                    <li className={"list-row"} key={user.id}>
                      <ul className={"list-data"}>
                        <li className={"hotel-name"}>{user.firstName}</li>
                        <li>{user.lastName}</li>
                        <li>{user.userRole}</li>
                        <li>{user.email}</li>
                        <li>Active</li>
                        <li>
                          <span className="edit">
                            <img
                              src="/images/edit-icon.svg"
                              onClick={() => this.editUserHandler(user)}
                              alt="Edit Icon"
                            />
                          </span>
                          <span className="delete">
                            <img
                              src="/images/delete-icon.svg"
                              onClick={() => this.deleteDialogHandler(user.id)}
                              alt="Delete Icon"
                            />
                          </span>
                        </li>
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.user.roles,
  userList: state.user.userList,
  hotelListData: state.hotelListReducer.list,
});

const mapDispatchToProps = (dispatch) => ({
  getRolesList: bindActionCreators(getRolesList, dispatch),
  getUserList: bindActionCreators(getUserList, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch),
  fetchHotelListData: bindActionCreators(fetchHotelListData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
