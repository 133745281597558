import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Icons from '../icons/icons'
import './modal.scss'

export default function Modal (props) {
  return (
    <div className={props.className}>
      <Dialog className={props.primary ? 'primary' : props.name ? 'add-user' : ''} disableBackdropClick={props.disableBackdropClick ? false : true} onClose={props.onClose} open={props.open} aria-labelledby='form-dialog-title' maxWidth={props.class} fullScreen={props.fullScreen} fullWidth classes={props.dialogClasses}>
        <DialogTitle id='form-dialog-title'>
          <Grid container spacing={3}>
            <Grid item xs={11}>{props.modalHeading}</Grid>
            <Grid item xs={1} align='right'><Icons icon='cancel' onClick={props.onClose} /></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent onScroll={props.onScroll} classes={props.dialogContentclasses}>
          <div className='title'>{props.modalTitle}</div>
          <div className='content'>{props.modalContent || props.children}</div>
        </DialogContent>
        {props.showActionBtn &&
          <DialogActions>
            {props.modalCloseTxt && <Button variant='contained' className={props.cxlbtnClass} onClick={props.onClose} color='secondary'>{props.modalCloseTxt}</Button>}
            {props.modalSaveTxt && <Button variant='contained' className={props.savebtnClass} onClick={props.onClick} color='primary'>{props.modalSaveTxt}</Button>}
          </DialogActions>}
      </Dialog>
    </div>
  )
}
