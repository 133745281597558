import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Compenent from './../../Component';
import { connect } from 'react-redux'
import Login from './../login'

export const routes = [
    {
        exact: false,
        path: '/login',
        component: Login
    },
    {
        exact: false,
        path: '',
        component: Compenent
    }
];

class AuthContent extends React.PureComponent {
    state = {
        hideHeader: false,
        setFooterSpace: false,
        hideInMobile: false,
    };

    render() {
        return (
            // <ScrollToTop>
                <Fragment>
                    <Switch>
                        {routes.map(route => <Route
                            key={route.path}
                            exact={!!route.exact}
                            path={route.path}
                            component={route.component} />)}
                    </Switch>
                </Fragment>
            // </ScrollToTop>
        )
    }
}

const mapStateToProps = state => ({
    
});
export default connect(mapStateToProps)(AuthContent)
