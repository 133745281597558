
import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 99999,
    color: '#fff'
  }
}))

const LoaderComponent = ({ isOpen = true }) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={isOpen}>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export default LoaderComponent
