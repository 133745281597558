import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './scss/login.scss'
import Buttons from '../../compenents/buttons/buttons'
import { Grid } from '@material-ui/core'
import Input from '../../compenents/inputs/input-simple'
import { login } from './../../actions/user-management.action'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailError: false,
      emailTouched: true,
      password: '',
      passwordError: false,
      passwordTouched: true
    }
  }

  componentDidMount() {
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.isLoggedIn){
      this.props.history.push('');
    }
  }

  handleLogin = async (e) => {
    e.preventDefault();
    let valid = true;
    // eslint-disable-next-line
    if (!(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/).test(this.state.email)) {
      valid = false;
      this.setState({ emailError: "Enter a valid Email!" });
    }
    if (this.state.password.trim() === "") {
      valid = false;
      this.setState({ passwordError: "Enter a valid Password!" });
    }
    if (valid) {
      this.props.login({ email: this.state.email, password: this.state.password })
    } else {
      e.preventDefault();
    }
  }

  render() {
    const { email, emailError, emailTouched, password, passwordError, passwordTouched } = this.state
    return (
      <div className='login'>
        <div className='logo'>
          <img src={('images/preductive-minds.png')} alt='Logo' />
          <p>Combining cutting-edge data science and technology with decades of industry experience to provide the most intuitively managed hospitality services.</p>
        </div>
        <div className='loginForm'>
          <div className='loginFormStart clearfix'>
            <form onSubmit={this.handleLogin}>
              <Grid xs={12}>
                <p className='LoginHeading'>Login</p>
                <label className='LoginLabel'>Your Email</label>
                <Input
                  grid={12}
                  type='email'
                  label='Email'
                  value={email}
                  valid={!emailError}
                  message={emailError}
                  touched={emailTouched}
                  onChange={(e) => this.setState({ email: e.target.value, emailTouched: true, emailError: false, })}
                />
                <label className='LoginLabel'>Password</label>
                <Input
                  type='password'
                  label='password'
                  value={password}
                  valid={!passwordError}
                  message={passwordError}
                  touched={passwordTouched}
                  onChange={(e) => this.setState({ password: e.target.value, passwordTouched: true, passwordError: false, })}
                />
                {/* <div className='ForgotDiv'>
                  <Input type='checkbox' label='Keep me logged in' />
                  <p>Forgot Password?</p>
                </div> */}
                <Buttons className='loginButton' type='submit' title='Login' color='secondary' />
              </Grid>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn
})

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
