import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import Login from './login'

const routes = [
  {
    exact: true,
    path: '',
    component: Login
  }
]

class Root extends React.PureComponent {
  render () {
    const { match } = this.props
    return (
      <div className='clearfix' style={{ width: '100%' }}>
        <TransitionGroup>
          <Switch>
            {routes.map(route => (
              <Route
                key={route.path}
                exact={!!route.exact}
                path={`${match.url}/${route.path}`}
                render={(props) => {
                  const Component = route.component
                  return <Component {...props} moduleURL={match.url} />
                }}
              />))}
          </Switch>
        </TransitionGroup>
      </div>
    )
  }
}
export default withRouter(Root)
