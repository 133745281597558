import { Cookies } from 'react-cookie'
import { BROWSER_STORAGE } from '../constants/action.constant'
const cookies = new Cookies()

export const setUserStorage = (token, domain) => {
  cookies.set(
    BROWSER_STORAGE.ACCESS_TOKEN,
    token,
    {
      path: '/'
    }
  )
}

export const getUserStorage = () => cookies.get(BROWSER_STORAGE.ACCESS_TOKEN)

export const removeUserStorage = (domain) => {
  cookies.set(
    BROWSER_STORAGE.ACCESS_TOKEN, '',
    {
      path: '/',
      maxAge: 0
    }
  )
  cookies.remove(BROWSER_STORAGE.ACCESS_TOKEN)
}
