import {
  SHOW_OVERLAY,
  HIDE_OVERLAY,
  SHOW_LOADER,
  HIDE_LOADER,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_MODULE_NAME,
  SHOW_ERROR_PAGE,
  HIDE_ERROR_PAGE,
  ERROR_NOT_FOUND
} from '../constants/action.constant'
import React from 'react'
import Button from '@material-ui/core/Button'

import { Clear } from '@material-ui/icons'

export const showErrorPage = (key, value) => ({ type: SHOW_ERROR_PAGE, key, value })

export const hideErrorPage = () => ({ type: HIDE_ERROR_PAGE })

export const errorNotFound = () => ({ type: ERROR_NOT_FOUND })

export const showOverlay = () => ({ type: SHOW_OVERLAY })

export const hideOverlay = () => ({ type: HIDE_OVERLAY })

export const showLoadingIndicator = () => ({ type: SHOW_LOADER })

export const hideLoadingIndicator = () => ({ type: HIDE_LOADER })

export const setModuleName = (name) => ({ type: SET_MODULE_NAME, name })

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  }
}

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
})

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
})

export const successSnackBar = (msg, options) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
        autoHideDuration: options?.autoHideDuration || 4000
      }
    }))
  }
}

export const errorSnackBar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
        autoHideDuration: 4000
      }
    }))
  }
}

export const warningSnackBar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
        autoHideDuration: 4000
      }
    }))
  }
}

export const infoSnackBar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'info',
        autoHideDuration: 4000
      }
    }))
  }
}

export const defaultSnackbar = (msg) => {
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: new Date().getTime() + Math.random(),
        autoHideDuration: 4000
      }
    }))
  }
}

export const persistantSnackbar = (msg, type) => {
  const myKey = new Date().getTime() + Math.random()
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: myKey,
        persist: true,
        variant: type,
        action: key => (
          <Button onClick={() => dispatch(closeSnackbar(myKey))}><Clear style={{ color: 'white' }} /></Button>
        )
      }

    }))
  }
}

export const simulateOnSnacker = (msg) => {
  const myKey = new Date().getTime() + Math.random()
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: myKey,
        persist: true,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      }

    }))
  }
}

export const customContent = (msg) => {
  const myKey = new Date().getTime() + Math.random()
  return (dispatch) => {
    dispatch(enqueueSnackbar({
      message: msg,
      options: {
        key: myKey,
        persist: true,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        content: () => (<h1>Hello</h1>)
      }

    }))
  }
}
