import React, { useState } from 'react'
import Modal from '../../compenents/modal/modal'
import './scss/add-competitor.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Input from '../../compenents/inputs/input-simple'
import { Grid } from '@material-ui/core'
import { addCompetitor } from '../../actions/hotelList.action'

function AddCompetitor (props) {
  const { dialogClose, open } = props

  const [name, setName] = useState('')
  const [url, setUrl] = useState('')

  const [nameError, setNameError] = useState(false)
  const [urlError, setUrlError] = useState(false)

  const changeHandler = e => {
    if (e.target.name === 'name') {
      setName(e.target.value.trim())
      setNameError(false)
      if (e.target.value.trim() === '') {
        setNameError('This field is Required')
      }
    }
    if (e.target.name === 'url') {
      setUrl(e.target.value.trim())
      setUrlError(false)
      if (e.target.value.trim() === '') {
        setUrlError('This field is Required')
      }
    }
  }
  const submitHandler = () => {
    let valid = true
    if (name === '') {
      setNameError('This field is Required')
      valid = false
    }
    if (url === '') {
      setUrlError('This field is Required')
      valid = false
    }

    if (valid) {
      props.addCompetitor({ name, url, description: '' }).then(res => {
        props.dialogClose()
      })
    }
  }
  return (
    <Modal
      open={open}
      onClose={dialogClose}
      class='sm'
      fullWidth='true'
      modalHeading='Add Competitor'
      modalSaveTxt='Save'
      modalCloseTxt='Cancel'
      onClick={submitHandler}
      showActionBtn
      savebtnClass='createBtn'
      primary
    >
      <div>
        <Grid xs={10} container className='subtaskrow-1' style={{ margin: '8px 0px' }}>
          <Grid xs={3} className='label' item style={{ margin: '8px 0px' }}>
            Name
          </Grid>
          <Grid xs={7} item>
            <Input
              name='name'
              type='text'
              label='Name'
              className='txtarea'
              error={nameError}
              valid={!nameError}
              touched
              onChange={(e) => changeHandler(e)}
            />
          </Grid>
          <Grid xs={3} className='label' item style={{ margin: '8px 0px' }}>
            Add Url
          </Grid>
          <Grid xs={7} item>
            <Input
              name='url'
              type='text'
              label='Enter Url'
              className='txtarea'
              error={urlError}
              valid={!urlError}
              touched
              onChange={(e) => changeHandler(e)}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
const mapStateToProps = state => ({
  user: state.user.userData
})
const mapDispatchToProps = dispatch => ({
  addCompetitor: bindActionCreators(addCompetitor, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(AddCompetitor)
