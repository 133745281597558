import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TextField, MenuItem, FormControl, InputLabel, Select, Checkbox,
  FormControlLabel, Grid, FormHelperText, FormLabel, Radio, RadioGroup, Switch
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers'
import './inputs.scss'
import DateFnsUtils from '@date-io/date-fns'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  radioFormControl: {
    marginLeft: theme.spacing(1)
  },
  radioLabel: {
    padding: '5px'
  },
  radio: {
    flexDirection: 'row'
  }
}))
export default function InputCommon(props) {
  const classes = useStyles()
  let input = null
  if (props.type === 'text') {
    input = (
      <Grid xs={props.grid ? props.grid : 12} item>
        <TextField
          id={!props.simple ? 'outlined-basic' : ''}
          variant={!props.simple ? 'outlined' : 'standard'} className={props.className} type={props.type} name={props.name} margin='dense' error={!props.valid && props.touched} helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange} type='text' variant='outlined' label={props.label} value={props.value} disabled={props.disable}
        />
      </Grid>)
  }
  if (props.type === 'textarea') {
    input = (
      <Grid xs={props.grid ? props.grid : 12} item>
        <TextField
          id='outlined-basic' className={props.className} type={props.type} name={props.name} margin='dense' error={!props.valid && props.touched} helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange} type='textarea' multiline rows='4' label={props.label}
          value={props.value} variant='outlined' disabled={props.disable}
        />
      </Grid>)
  }
  if (props.type === 'password') {
    input = (
      <Grid xs={props.grid ? props.grid : 12} item>
        <TextField
          id='outlined-basic' margin='dense' name={props.name} type={props.type} error={!props.valid && props.touched} helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange} type='password' label={props.label} value={props.value} variant='outlined' disabled={props.disable}
        />
      </Grid>)
  }

  if (props.type === 'email') {

    input = (
      <Grid xs={props.grid ? props.grid : 12} item>
        <TextField
          id='outlined-basic' type={props.type} margin='dense' name={props.name} type={props.type} error={!props.valid && props.touched} helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange} type='email' label={props.label} value={props.value} variant='outlined' disabled={props.disable}
        />
      </Grid>)
  }

  // if (props.type === 'password') {
  //   input = (
  //     <Grid xs={props.grid ? props.grid : 12} item>
  //       <TextField
  //         id='outlined-basic' margin='dense' error={!props.valid && props.touched} type={props.type} helperText={!props.valid && props.touched ? props.error : null}
  //         onChange={props.onChange} type={props.showPassword ? 'text' : 'password'} label={props.label} value={props.value} variant='outlined' disabled={props.disable}
  //         endadornment={
  //           <InputAdornment position='end'>
  //             <IconButton
  //               aria-label='toggle password visibility'
  //               onClick={() => {
  //                 props.showPassword = !props.showPassword
  //               }}
  //             >
  //               {props.showPassword ? <Visibility /> : <VisibilityOff />}
  //             </IconButton>
  //           </InputAdornment>
  //         }
  //       />
  //     </Grid>)
  // }

  if (props.type === 'number') {
    input = (
      <Grid xs={props.grid ? props.grid : 12} item>
        <TextField
          id={!props.simple ? 'outlined-basic' : ''} variant={!props.simple ? 'outlined' : 'standard'} margin='dense' name={props.name} type={props.type} className={props.className} error={!props.valid && props.touched} helperText={!props.valid && props.touched ? props.error : null}
          onChange={props.onChange} type='number' label={props.label} value={props.value} disabled={props.disable}
          inputProps={props.inputProps} />
      </Grid>)
  }

  if (props.type === 'switch') {
    input = (
      <Grid xs={props.grid ? props.grid : 12} item>
        <Switch
          name={props.name}
          onChange={props.onChange} checked={props.value}
          inputProps={{ 'aria-label': 'primary checkbox' }} />
      </Grid>
    )
  }

  if (props.type === 'select') {
    const menuItem = props.items.map(res => {
      return <MenuItem value={res.value}>{res.key}</MenuItem>
    })
    input = (
      <Grid xs={props.grid ? props.grid : 12} className='selectWidth' item>
        <FormControl variant={!props.simple ? 'outlined' : ''} margin='dense' error={!props.valid && props.touched} fullWidth>
          <InputLabel id='demo-simple-select-outlined-label'>{props.label}</InputLabel>
          <Select
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            onChange={props.onChange}
            name={props.name}
            className={props.className}
            label={props.label}
            type={props.type}
            value={props.value}
            disabled={props.disable}
          >
            {menuItem}
          </Select>
          <FormHelperText>{!props.valid && props.touched ? props.error : null}</FormHelperText>
        </FormControl>
      </Grid>
    )
  }

  if (props.type === 'radio') {
    input = (
      <FormControl className='cs_radio'>
        <FormLabel component='legend'>{props.label}</FormLabel>
        <RadioGroup
          aria-label={props.arialabel}
          name={props.name}
          value={props.value}
          color='primary'
          row
          onChange={props.onChange}
          className={classes.radio}
        >
          {props.options.map((option) => {
            return <FormControlLabel
              className='cs_radio'
              key={option.key}
              value={option.value}
              control={<Radio color='primary' />} label={option.text}
            />
          })}
        </RadioGroup>
      </FormControl>
    )
  }
  if (props.type === 'checkbox') {
    input = (<FormControlLabel
      control={
        <Checkbox
          checked={props.value !== undefined ? props.value : false}
          onChange={props.onChange}
          name={props.name}
          type={props.type}
          color='primary'
          disabled={props.disable}
          key={props.keyIndex}
        />
      }
      label={props.label}
    />
    )
  }

  if (props.type === 'phoneNumber') {
    input = (
      <div className='phoneNumberContainer'>
        <PhoneInput
          placeholder={props.label}
          name='phoneNumber'
          onChange={props.onChange}
          value={props.value}
          flags={flags}
          defaultCountry="AU"
          disabled={props.disable}
        />
        <span className='phoneNumberError'>{props.error}</span>
      </div>
    )
  }

  if (props.type === 'permission') {
    let activePermissions = [];
    props.permissions.filter(permission => permission.value && permission.default).forEach(permission => {
      activePermissions = activePermissions.concat(permission.default);
    });
    input = (
      <Grid xs={props.grid ? props.grid : 12} item container spacing={2}>
        <Grid xs={props.grid ? props.grid : 4} item>
          {props.label}
        </Grid>
        <Grid xs={props.grid ? props.grid : 8} item>
          {props.permissions.map(permission =>
            <FormControlLabel
              control={
                <Checkbox
                  checked={permission.value || activePermissions.includes(permission.name)}
                  name={permission.name}
                  onClick={props.onSelection}
                  disabled={permission.disabled || activePermissions.includes(permission.name)}
                  color='primary'
                />
              }
              label={permission.label}
            />
          )}
        </Grid>
      </Grid>
    )
  }
  if (props.type === 'datepicker') {
    const dateElement =
      <DatePicker
        inputVariant='outlined'
        margin='dense'
        label={props.label}
        error={!props.valid && props.touched}
        helperText={!props.valid && props.touched ? props.error : null}
        name={props.name}
        format='MM/dd/yyyy'
        value={props.value || ''}
        onChange={props.onChange}
        inputProps={{ value: props.value || '' }}
        {...props}
      />
    input = (
      <>
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {dateElement}
          </MuiPickersUtilsProvider>
        </div>
      </>)
  }

  return (
    <>
      {input}
    </>
  )
}
