import { BEConfig } from '../config/env'
import { fetchWebApi, postAuthWebApi, putAuthWebApi } from '../webapis/apiResource'
import { getAccessToken } from '../util/auth'
import { showLoadingIndicator, hideLoadingIndicator, successSnackBar, errorSnackBar } from '../actions/common.action'
import { handleFetchError } from '../util/errorHandler'
import { GET_HOTEL_LIST_DATA } from '../constants/action.constant'
import store from '../store'

export const getHotelListData = list => ({ type: GET_HOTEL_LIST_DATA, list })

export const fetchHotelListData = (showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, hotelListPageHandle } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${hotelListPageHandle}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        if (response.data && response.data.length) {
          dispatch(getHotelListData(response.data))
        }
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const addCompetitor = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, addCompetitor } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${addCompetitor}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('Competitor Added Succesfully'))
        return response
      })
      .catch(error => {
        store.dispatch(errorSnackBar('Something went wrong, please try again.'))
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const addHotel = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, addHotel } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${addHotel}`
  data.User_Id = 'N/A'
  data.Password = 'N/A'
  data.Property_Id = data.Property_Id || 'N/A'
  data.Competitors = []
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('Hotel Added Succesfully'))
        return response
      })
      .catch(error => {
        store.dispatch(errorSnackBar('Something went wrong, please try again.'))
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const setAlgo = (data, isNew, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, setAlgo, updateAlgo } = BEConfig
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    if (isNew) {
      const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${setAlgo}`
      return postAuthWebApi(getAccessToken(), apiURL, data)
        .then(response => {
          if (showLoader) dispatch(hideLoadingIndicator())
          store.dispatch(successSnackBar('Algo Saved Succesfully'))
          return response
        })
        .catch(error => {
          store.dispatch(errorSnackBar('Something went wrong, please try again.'))
          if (showLoader) dispatch(hideLoadingIndicator())
          handleFetchError(error, dispatch)
          return error.response
        })
    } else {
      const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${updateAlgo}`
      return putAuthWebApi(getAccessToken(), apiURL, data)
        .then(response => {
          if (showLoader) dispatch(hideLoadingIndicator())
          store.dispatch(successSnackBar('Algo Saved Succesfully'))
          return response
        })
        .catch(error => {
          store.dispatch(errorSnackBar('Something went wrong, please try again.'))
          if (showLoader) dispatch(hideLoadingIndicator())
          handleFetchError(error, dispatch)
          return error.response
        })
    }
  }
}