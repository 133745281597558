import {
    GET_HOTEL_LIST_DATA
  } from '../constants/action.constant'
  
  const initialState = {
    cart: []
  }
  
  const getHotelListData = (state, { list }) => ({ ...state, list })
  
  const hotelListReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_HOTEL_LIST_DATA:
        return getHotelListData(state, action);

      default:
        return state;
    }
  }
  
  export default hotelListReducer
  