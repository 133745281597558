import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";
import { logout } from "../../../actions/user-management.action";
import { downloadReport, fetchAiPriceGenerator } from "../../../actions/dashboard.action";

// class NnotificationBar extends Component {
//   logout = () => {
//     debugger
//     const history = useHistory()
//     this.props.logout()
//     history.push('/login')
//   }
//     render(){
//         return(
//             <div className="notification_bar">
//                 <a className="faq"><img src="/images/support.svg" alt="Support" /></a>
//                 <a className="notification"><img src="/images/notification.svg" alt="Notification" /></a>
//                 <a className="settings"><img src="/images/settings.svg" alt="Settings" /></a>
//                 <a className="logout" onClick={this.logout} ><img src="/images/logout.svg" alt="Logout" /></a>
//             </div>
//         )
//     }
// }

function NotificationBar(props) {
  const history = useHistory();

  const logout = () => {
    props.logout();
    window.location.href = "/login";
  };

  const downloadReport = () => {
    props.downloadReport(true);
  };

  const isShowAiGeneratorBtn = props?.user?.role === "Admin";

  return (
    <div className="notification_bar">
      {isShowAiGeneratorBtn && (
        <div>
          <button onClick={() => props?.fetchAiPriceGenerator?.()} className="btn ai-price-generator">AI Price Generator</button>
        </div>
      )}
      {/* <a className="faq"><img src="/images/support.svg" alt="Support" /></a>
      <a className="notification"><img src="/images/notification.svg" alt="Notification" /></a>
      <a className="settings"><img src="/images/settings.svg" alt="Settings" /></a> */}
      <a className="logout" onClick={logout}>
        <img src="/images/logout.svg" alt="Logout" />
        Logout
      </a>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  logout: bindActionCreators(logout, dispatch),
  downloadReport: bindActionCreators(downloadReport, dispatch),
  fetchAiPriceGenerator: bindActionCreators(
    fetchAiPriceGenerator,
    dispatch
  ),
});



export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
