import {
  SAVE_USER_DATA,
  REMOVE_USER_DATA,
  ROLE_LIST_DATA,
  USER_LIST_DATA
} from '../constants/action.constant'

const initialState = {
  userData: {
    isLoggedIn: false,
    name: '',
    role: '',
    email: '',
    phoneNumber: '',
    permissions: {
      projectManagement: 0,
      userManagement: 0
    }
  },
  roles: [],
  userList: []
}

const saveUserData = (state, action) => {
  const { firstName, lastName, userRole, email, id, projectIds } = (action.data && action.data.data) || {}

  const userData = {
    name: `${firstName} ${lastName}`,
    role: userRole,
    email: email,
    id: id,
    projects: projectIds
  }
  return ({
    ...state,
    userData,
    isLoggedIn: true
  })
}

const saveUserList = (state, action) => {
  const userList = action.data

  return ({
    ...state,
    userList,
    isLoggedIn: true
  })
}

const saveUserRoles = (state, action) => {
  const roles = action.data
  return ({
    ...state,
    roles,
    isLoggedIn: true
  })
}

const removeUserData = (state, data) => {
  return { ...state, isLoggedIn: false }
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_DATA: return saveUserData(state, action)
    case REMOVE_USER_DATA: return removeUserData(state, action)
    case ROLE_LIST_DATA: return saveUserRoles(state, action)
    case USER_LIST_DATA: return saveUserList(state, action)
    default: return state
  }
}

export default userReducer
