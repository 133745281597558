import React, { Component } from "react";
import Logo from "./Logo";
import Search from "./Search";
import NotificationBar from "./NotificationBar";
import UserIcon from "./UserIcon";
import $ from "jquery";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    this.props.openDrawer();
    // $("body").toggleClass("sideBar");
  };

  render() {
    const { openDrawer } = this.props;
    return (
      <header className="header">
        <div className="container clearfix">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <button onClick={this.handleClick} className="openbtn">
                ☰
              </button>
              <Logo />
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <div className="header-right">
                {/* <Search /> */}
                <NotificationBar />
                {/* <UserIcon /> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({ common }) => ({
  showSideBar: common.showSideBar,
});

export default Header;
