import React from 'react'
import NotificationsSystem from 'reapop'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App/App'
import store from './App/store'
import Notifier from './App/compenents/snackbar'
import * as serviceWorker from './serviceWorker'

// Importing Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css'

// Importing Global styles
import './scss/core.scss'

// Importing Semantic UI
import 'semantic-ui-css/semantic.min.css'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider maxSnack={10}>
        <Notifier />
        <App userAgent={navigator.userAgent} />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
