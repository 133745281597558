import {
  GET_DASHBOARD_DATA,
  CLEAR_DASHBOARD_DATA,
  GET_HOTEL_SEARCH_DATA,
  GET_AI_PRICE_GENERTOR
} from '../constants/action.constant'

const initialState = {
    cart: [],
    hotels: {hotelPrices: []},
    hotelSearchResults: []
}

const getDashboardData = (state, { hotels }) => ({ ...state, hotels })

const clearDashboardData = (state) => ({ ...state, hotels: [] })

const getHotelSearchData = (state, { results }) => ({ ...state, hotelSearchResults: [...results] })

const getAIPriceGeneratorData = (state, { results }) => ({ ...state, aiPriceGenerator: results })


const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA: 
    return getDashboardData(state, action)
    case CLEAR_DASHBOARD_DATA: 
    return clearDashboardData(state)
    case GET_HOTEL_SEARCH_DATA: 
    return getHotelSearchData(state, action)
    case GET_AI_PRICE_GENERTOR: 
    return getAIPriceGeneratorData(state, action)
    default: return state
  }
}

export default dashboardReducer
