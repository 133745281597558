import idx from 'idx'
import { showLoadingIndicator, hideLoadingIndicator, successSnackBar, errorSnackBar } from '../actions/common.action'
import { fetchWebApi, postAuthWebApi, deleteWebApi } from '../webapis/apiResource'
import { saveAccessToken, removeAccessToken } from './../util/auth'
import { handleFetchError } from '../util/errorHandler'
import { BEConfig } from '../config/env'
import { getAccessToken } from '../util/auth'
import store from '../store'
import {
  SAVE_USER_DATA,
  REMOVE_USER_DATA,
  USER_LIST_DATA,
  ROLE_LIST_DATA
} from '../constants/action.constant'

export const saveUserData = data => ({ type: SAVE_USER_DATA, data })

export const getUserListData = data => ({ type: USER_LIST_DATA, data })

export const getRolesListData = data => ({ type: ROLE_LIST_DATA, data })

export const removeUserData = () => ({ type: REMOVE_USER_DATA })

export const getUserList = (showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, userList } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${userList}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return fetchWebApi(getAccessToken(), apiURL)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        if (response.data && response.data.length) {
          dispatch(getUserListData(response.data))
        }
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const deleteUser = (userId, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, deleteUser } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${deleteUser}${userId}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return deleteWebApi(getAccessToken(), apiURL)
      .then(response => {
        store.dispatch(errorSnackBar('Something went wrong, please try again.'))
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('User Deleted Succesfully'))
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const addUser = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, addUser } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${addUser}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('User Added Succesfully'))
        return response
      })
      .catch(error => {
        store.dispatch(errorSnackBar('Something went wrong, please try again.'))
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const editUser = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, editUser } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${editUser}${data.userId}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        store.dispatch(successSnackBar('User Updated Succesfully'))
        return response
      })
      .catch(error => {
        store.dispatch(errorSnackBar('Something went wrong, please try again.'))
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}
export const getRolesList = (showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, rolesList } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${rolesList}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return fetchWebApi(getAccessToken(), apiURL)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        if (response.data && response.data.length) {
          dispatch(getRolesListData(response.data))
        }
        return response
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const login = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, login } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${login}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then(response => {
        saveAccessToken(response.data && response.data.token)
        dispatch(getUserData())
      })
      .catch(error => {
        store.dispatch(errorSnackBar('Invalid Email/password'))
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    removeAccessToken()
    dispatch(removeUserData())
  }
}

export const getUserData = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, userDetails } = BEConfig
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${userDetails}`
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator())
    return fetchWebApi(getAccessToken(), apiURL)
      .then(response => {
        if (showLoader) dispatch(hideLoadingIndicator())
        dispatch(saveUserData(idx(response, _response => _response)))
      })
      .catch(error => {
        if (showLoader) dispatch(hideLoadingIndicator())
        handleFetchError(error, dispatch)
        return error.response
      })
  }
}
