import React, { useEffect } from "react";

import TextField from "@material-ui/core/TextField";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHotelSearchResults } from "../../actions/dashboard.action";

import Autocomplete from "@material-ui/lab/Autocomplete";

const HotelSearchInput = ({
  hotelSearchResults,
  fetchHotelSearchResultsQuery,
  onSearchSelected,
  hotelError,
}) => {
  useEffect(() => loadSearchData("a"), []);

  const loadSearchData = (value = "") => {
    fetchHotelSearchResultsQuery(value, false);
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      onChange={onSearchSelected}
      options={hotelSearchResults}
      getOptionLabel={(option) => option?.name}
      style={{ width: "100%", height: 40 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Hotel Name"
          variant="outlined"
          size="small"
          onChange={(e) => loadSearchData(e.target.value)}
          error={hotelError}
          helperText={hotelError && "This field is required!"}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  hotelSearchResults: state.dashboardReducer.hotelSearchResults,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHotelSearchResultsQuery: bindActionCreators(
    fetchHotelSearchResults,
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(HotelSearchInput);
