import React, {Component} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { Icon } from 'semantic-ui-react';
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { refreshHotelPrice } from "../../../../actions/editHotel.action";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'transparent',
      color: '#777777',
      fontSize: theme.typography.pxToRem(12),
      width: 'auto',
      lineHeight: '20px',
    },
}))(Tooltip);


class HotelTopSection extends Component{
    handleRefresh = (hotel) => {
        this.props.refreshHotelPrice(hotel.id, true);
    
    }
    render(){
        const {hotelData, noOfDays, userRole} = this.props
        
        return(
            <div className="hotel-top-container">
                <div className="hotel-name">
                    <p>
                        {hotelData.name}
                        {userRole !== 'Client' && <Tooltip title="Scrape">
                            <IconButton
                                color="primary"
                                onClick={() => this.handleRefresh(hotelData)}
                            >
                                <img src="/images/robo.png" alt="" style={{
                                width: '18px',
                                borderRadius: '50%'
                                }} />
                            </IconButton>
                        </Tooltip>}
                    </p>
                    <Icon name='dropdown' />
                </div>
                <ul className="hotel-data-container">
                    { hotelData.prices.map((item,index)=>{
                        if(index<noOfDays){
                            return (
                              <HtmlTooltip
                                key={index}
                                title={
                                  <div
                                    style={{
                                      width: "319px",
                                      backgroundColor: "#fff",
                                      border: "1px solid #dadde9",
                                      padding: "16px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <ul
                                      className="top-container"
                                      style={{
                                        display: "inline-block",
                                        width: "49%",
                                      }}
                                    >
                                      <li
                                        className="percentage"
                                        style={{
                                          display: "block",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        Rate: {item.price} USD{" "}
                                      </li>
                                      <li
                                        style={{
                                          display: "block",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        {item.description1}{" "}
                                      </li>
                                      <li style={{ display: "block" }}>
                                        {item.description2}
                                      </li>
                                    </ul>
                                    <ul
                                      className="top-container"
                                      style={{
                                        display: "inline-block",
                                        width: "49%",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      <li
                                        className="date"
                                        style={{
                                          marginBottom: "8px",
                                          display: "block",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          verticalAlign: "top",
                                          textAlign: "right",
                                        }}
                                      >
                                        {moment(item.from_Date).format(
                                          "dddd DD MMM, YYYY"
                                        )}
                                      </li>
                                      <li
                                        style={{
                                          display: "flex",
                                          backgroundColor: "#252F76",
                                          color: "#fff",
                                          width: "24px",
                                          height: "24px",
                                          textAlign: "center",
                                          float: "right",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span>B</span>
                                      </li>
                                    </ul>
                                    <ul
                                      className="top-container"
                                      style={{
                                        display: "inline-block",
                                        width: "100%",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      <li
                                        className="date"
                                        style={{
                                          marginBottom: "8px",
                                          display: "flex",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          verticalAlign: "top",
                                          textAlign: "left",
                                        }}
                                      >
                                        Last Scrapped On:{" "}
                                      </li>
                                      <li
                                        className="date"
                                        style={{
                                          marginBottom: "8px",
                                          display: "flex",
                                          fontSize: "14px",
                                          verticalAlign: "top",
                                          textAlign: "right",
                                        }}
                                      >
                                        {moment(item?.creationDate).format(
                                          "dddd Do MMMM h:mm A"
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                }
                                placement="bottom"
                              >
                                <li key={index}>
                                  ${item.price}
                                  {item.priceDiff !== 0 && (
                                    <span
                                      className={`varibale-price ${
                                        item.priceDiff > 0 ? "positive" : ""
                                      }`}
                                    >
                                      {Math.round(item.priceDiff)}
                                    </span>
                                  )}
                                </li>
                              </HtmlTooltip>
                            );  

                        }else {
                            return false
                        }

                    })}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.userData.role
});
  
  const mapDispatchToProps = (dispatch) => ({
    refreshHotelPrice: bindActionCreators(refreshHotelPrice, dispatch),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(HotelTopSection);