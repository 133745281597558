import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const history = createBrowserHistory()

const middleware = [thunk, routerMiddleware(history)]
const environment = (process.env.NODE_ENV || 'development').toString().trim().toLowerCase()
if (environment !== 'production' && typeof window !== 'undefined') {
  // middleware.push(createLogger())
}
let initialState = {}
typeof window !== 'undefined' && (initialState = window.__REDUX_STATE__)
const store = createStore(rootReducer, initialState, (compose(applyMiddleware(...middleware)))
)

store.subscribe(() => {
  // console.log('Store updated: ', store.getState())
})
export default store
