import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserData } from "../../actions/user-management.action";
import { Accordion, Icon } from "semantic-ui-react";

import CloseIcon from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonIcon from "@material-ui/icons/Person";
import EventIcon from "@material-ui/icons/Event";
import RateReviewIcon from "@material-ui/icons/RateReview";

import "./style.scss";

const SideBar = ({ userData, getUserData, closeDrawer }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  // useEffect(() => {
  //   getUserData();
  // }, [getUserData]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <div className="sidebar-container">
      <img src="/images/logo.png" alt="logo" className="sidebar-logo" />

      <div className="text-right pr-3 close-icon">
        <CloseIcon onClick={closeDrawer} />
      </div>

      <NavLink
        closeDrawer={closeDrawer}
        IconComponent={DashboardIcon}
        title={"Dashboard"}
        route={"/"}
      />

      {userData && userData.role !== "Client" && (
        <NavLink
          closeDrawer={closeDrawer}
          IconComponent={SettingsIcon}
          title={"Manage Hotels"}
          route={"/hotel-list"}
        />
      )}

      {userData &&
        userData.role !== "Client" &&
        userData.role !== "Resource Manager" && (
          <NavLink
            closeDrawer={closeDrawer}
            IconComponent={PersonIcon}
            title={"User Management"}
            route={"/user-management"}
          />
        )}

      {userData && userData.role === "Admin" && (
        <>
          <NavLink
            closeDrawer={closeDrawer}
            IconComponent={EventIcon}
            title={"Event Management"}
            route={"/event-management"}
          />
          <NavLink
            closeDrawer={closeDrawer}
            IconComponent={RateReviewIcon}
            title={"Hotel Review Form"}
            route={"/review-form/list"}
          />
        </>
      )}

      {userData && userData.role !== "Client" && (
        <div className="navlink-container" style={{ padding: "2px 10px" }}>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleClick}
              style={{
                color: "#777",
                fontSize: "16px",
                lineHeight: "22px",
              }}
            >
              <div className="reports-container">
                <Icon name="dropdown" />
                <p> Reports</p>
              </div>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                <a
                  style={{
                    padding: "17px",
                    color: "#777",
                    fontSize: "16px",
                    lineHeight: "22px",
                    paddingLeft: "41px",
                  }}
                  href={
                    "https://lookerstudio.google.com/u/0/reporting/5b8d36d3-c079-4885-8e76-00fc91f9e11c/page/0lm3C"
                  }
                  target="_blank"
                >
                  Reputaion Reports
                </a>
              </p>
              <p>
                <a
                  style={{
                    padding: "17px",
                    color: "#777",
                    fontSize: "16px",
                    lineHeight: "22px",
                    paddingLeft: "41px",
                  }}
                  href={
                    "https://lookerstudio.google.com/u/0/reporting/f2a773a2-6ed1-4b11-bcb8-e2b86f6f6b9c/page/07b2C"
                  }
                  target="_blank"
                >
                  Revenue Reports
                </a>
              </p>
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </div>
  );
};

const NavLink = ({ IconComponent, title, route, closeDrawer }) => {
  return (
    <Link to={route} onClick={closeDrawer} className="navlink-container">
      <IconComponent />
      <p>{title}</p>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  userData: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: bindActionCreators(getUserData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
