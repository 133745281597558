import React, { useEffect, useState } from "react";
import Header from "./Header";
import Navigationbars from "./NavigationBar";
import { Route, Switch, withRouter } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import createRoute from "../util/createRoute";
import { routes } from "../config/route.config";
import $ from "jquery";
import Loader from "./Loader";

import Drawer from "@material-ui/core/Drawer";
import SideBar from "./SideBar";

const Main = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollTop;
      setIsScrolled(scroll >= 20);
    };

    $(window).scroll(handleScroll);

    return () => {
      $(window).off("scroll", handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <div>
      <Header openDrawer={() => setIsOpenDrawer(true)} />
      <Drawer
        anchor="left"
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
      >
        <SideBar closeDrawer={() => setIsOpenDrawer(false)} />
      </Drawer>

      {/* <Navigationbars /> */}
      <div
        className={`mainComponent ${isScrolled ? "scroll" : ""}`}
        id="mainComponent"
      >
        <TransitionGroup>
          <Switch>{createRoute(routes)}</Switch>
        </TransitionGroup>
      </div>
      <Loader />
    </div>
  );
};

export default withRouter(Main);
