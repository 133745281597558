import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';

const RouteWithSubRoutes = (route) => {
    return (
        <Route path={route.path} exact= {route.exact || false} render={props => {
            // pass the sub-routes down to keep nesting
            return (<route.component {...props} routes={route.routes} />)
        }
        } />
    )
};

const createRoute = (routes) => (routes && routes.map && routes.map((route, i) => (<RouteWithSubRoutes key={i} {...route} />)));
export default createRoute;
