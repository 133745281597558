import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'
import CancelIcon from '@material-ui/icons/Cancel'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import './icons.scss'

export default function Icons (props) {
  let element
  switch (props.icon) {
    case 'add':
      element = <AddIcon />
      break
    case 'remove':
      element = <Remove />
      break
    case 'cancel':
      element = <CancelIcon onClick={props.onClick} />
      break
    case 'edit':
      element = <Edit />
      break
    case 'trash':
      element = <Delete />
      break
    default:
  }
  return (
    <div className='icon'>
      {element}
    </div>
  )
}
