import { combineReducers } from 'redux'
import { reducer as notifications } from 'react-notification-system-redux'
import dashboardReducer from './dashboard.reducer'
import hotelListReducer from './hotelList.reducer'
import editHotelReducer from './editHotel.reducer'
import reviewFormReducer from './reviewForm.reducer'
import common from './common.reducer'
import user from './user.reducer'
const rootReducer = combineReducers({
  notifications,
  dashboardReducer,
  hotelListReducer,
  editHotelReducer,
  common,
  user,
  reviewFormReducer
})

export default rootReducer
