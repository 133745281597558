import React, { useEffect } from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHotelSearchResults } from "../../actions/dashboard.action";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { createEvent } from "../../actions/events.action";

const CreateEventDialog = ({
  handleClose,
  hotelSearchResults,
  fetchHotelSearchResultsQuery,
  createEvent: createEventProps,
}) => {
  const [hotelDetails, setHotelDetails] = React.useState(false);
  const [hotelError, setHotelError] = React.useState(false);

  const [eventName, setEventName] = React.useState(undefined);
  const [eventNameError, setEventNameError] = React.useState(false);

  const [eventDate, setEventDate] = React.useState(undefined);
  const [eventDateError, setEventDateError] = React.useState(false);

  const [isGlobalEvent, setIsGlobalEvent] = React.useState(false);

  useEffect(() => loadSearchData("a"), []);

  const onSearchSelected = (_, hotel) => {
    setHotelDetails(hotel);

    if (!hotel) {
      setHotelError(true);
    } else {
      setHotelError(false);
    }
  };

  const loadSearchData = (value = "") => {
    fetchHotelSearchResultsQuery(value, false);
  };

  const handleSubmit = async () => {
    let isFormValid = true;

    if (!hotelDetails && !isGlobalEvent) {
      setHotelError(true);
      isFormValid = false;
    }

    if (!eventName) {
      setEventNameError(true);
      isFormValid = false;
    }

    if (!eventDate) {
      setEventDateError(true);
      isFormValid = false;
    }

    if (isFormValid) {
      const payload = {
        EventName: eventName,
        EventDate: new Date(eventDate).toISOString(),
        IsGlobalEvent: isGlobalEvent,
      };

      if (!payload.IsGlobalEvent) {
        payload.HotelID = hotelDetails.id;
      }

      await createEventProps(payload);
      handleClose(true);
    }
  };

  return (
    <Dialog
      minWidth={400}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open
    >
      <DialogTitle id="simple-dialog-title">Create Event</DialogTitle>
      <DialogContent>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={isGlobalEvent}
              onChange={(e) => {
                setIsGlobalEvent(e.target.checked);
                setHotelError(false);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Is Global Event?"
        /> */}

        <div style={{ width: "400px" }}>
          <Autocomplete
            id="combo-box-demo"
            onChange={onSearchSelected}
            options={hotelSearchResults}
            getOptionLabel={(option) => option?.name}
            style={{ width: "100%", height: 40 }}
            disabled={isGlobalEvent}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Hotel Name"
                variant="outlined"
                size="small"
                onChange={(e) => loadSearchData(e.target.value)}
                error={hotelError}
                helperText={hotelError && "This field is required!"}
                disabled={isGlobalEvent}
              />
            )}
          />

          <div className="my-4">
            <TextField
              variant="outlined"
              size="small"
              placeholder="Enter event name"
              label="Event Name"
              style={{ width: "100%" }}
              error={eventNameError}
              helperText={eventNameError && "This field is required!"}
              onChange={(e) => {
                const value = e.target.value?.trim();

                setEventName(value);

                if (value) {
                  setEventNameError(false);
                } else {
                  setEventNameError(true);
                }
              }}
            />
          </div>

          <div className="mb-4">
            <TextField
              variant="outlined"
              size="small"
              placeholder="Enter event name"
              type="date"
              style={{ width: "100%" }}
              error={eventDateError}
              helperText={eventDateError && "This field is required!"}
              inputProps={{ min: new Date().toISOString().split("T")[0] }}
              onChange={(e) => {
                const value = e.target.value?.trim();

                setEventDate(value);

                if (value) {
                  setEventDateError(false);
                } else {
                  setEventDateError(true);
                }
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-flex justify-content-around w-100">
          <button className="primary-btn" onClick={handleSubmit}>
            Save
          </button>
          <button className="secondary-btn" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  hotelSearchResults: state.dashboardReducer.hotelSearchResults,
  reviewFormDataByHotelId: state.reviewFormReducer.reviewFormDataByHotelId,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHotelSearchResultsQuery: bindActionCreators(
    fetchHotelSearchResults,
    dispatch
  ),
  createEvent: bindActionCreators(createEvent, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateEventDialog);
