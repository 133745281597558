import React, {Component} from 'react';

export default class AIPriceData extends Component{
    render(){
        return (
            <ul className="AIprice-data-container">
                <li>
                    $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
                <li>
                $47
                    <span className="varibale-price">+1</span>
                </li>
            </ul>
        )
    }
}