import { BEConfig } from '../config/env'
import {
  deleteWebApi,
  fetchWebApi,
  postAuthWebApi,
} from "../webapis/apiResource";
import { getAccessToken } from "../util/auth";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  successSnackBar,
  errorSnackBar,
} from "../actions/common.action";
import { handleFetchError } from "../util/errorHandler";
import {
  GET_REVIEW_FORM_DATA_BY_HOTEL_ID,
  GET_REVIEW_FORM_LIST_DATA,
} from "../constants/action.constant";
import store from "../store";

export const getReviewFormListData = (list) => ({
  type: GET_REVIEW_FORM_LIST_DATA,
  list,
});

export const getReviewFormByHotelId = (reviewFormData) => ({
  type: GET_REVIEW_FORM_DATA_BY_HOTEL_ID,
  reviewFormData,
});

export const fetchReviewFormListData = (showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, reviewFormList } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${reviewFormList}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        if (response.data && response.data.length) {
          dispatch(getReviewFormListData(response.data));
        }
        return response;
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchReviewFormByHotelId = (hotelId, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, getReviewById } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${getReviewById}/${hotelId}`;
  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        if (response.data) {
          dispatch(getReviewFormByHotelId(response.data));
        }
        return response;
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const createReviewForm = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, addReveiwForm } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${addReveiwForm}`;

  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(successSnackBar("Hotel Reveiw Form Added Succesfully"));
        return response;
      })
      .catch((error) => {
        store.dispatch(
          errorSnackBar("Something went wrong, please try again.")
        );
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateReviewForm = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, updateReviewForm } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${updateReviewForm}`;

  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(
          successSnackBar("Hotel Reveiw Form Updated Succesfully")
        );
        return response;
      })
      .catch((error) => {
        store.dispatch(
          errorSnackBar("Something went wrong, please try again.")
        );
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const deleteReviewForm = (hotelID, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, deleteReviewById } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${deleteReviewById}/${hotelID}`;

  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(), apiURL)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(
          successSnackBar("Hotel Reveiw Form Deleted Succesfully")
        );
        return response;
      })
      .catch((error) => {
        store.dispatch(
          errorSnackBar("Something went wrong, please try again.")
        );
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};