export const PATH_URL = {
  HOME: "/",
  HOTEL_LIST: "/hotel-list",
  USER_MANAGEMENT: "/user-management",
  HOTEL_EDIT: "/editHotel/:name",
  REVIEW_FORM_LIST: "/review-form/list",
  REVIEW_FORM_CREATE: "/review-form/create",
  REVIEW_FORM_EDIT: "/review-form/edit/:hotelId",
  EVENT_MANAGEMENT: "/event-management",
};
