import React, {Component} from 'react';

class UserIcon extends Component{
    render(){
        return(
            <div className="header_profile">
                <a className="profile_icon">
                    <img src="/images/image.jpeg" alt="User Icon" />
                </a>
            </div>
        )
    }
}

export default UserIcon;