import {
    GET_COMPETITORS_LIST,
    GET_HOTEL_DATA
} from '../constants/action.constant'

const initialState = {
    data: {},
    competitorsList: []
}

const getHotelData = (state, { data }) => ({ ...state, data })
const getCompetitorsList = (state, { data }) => ({ ...state, competitorsList:data })

const editHotelReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOTEL_DATA:
            return getHotelData(state, action)
        case GET_COMPETITORS_LIST:
            return getCompetitorsList(state, action)

        default: return state
    }
}

export default editHotelReducer
