import React, {Component} from "react";

class UserModal extends Component{
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div>
                Modal
            </div>

        )
    }
}

export default UserModal;