import React, { Component } from "react";
import moment from "moment";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class GraphData extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
  }
  componentDidMount() {
    const { hotelData, noOfDays, mainIndex } = this.props;
    hotelData &&
      hotelData.prices.length &&
      this.initializeGraph(hotelData, noOfDays, mainIndex);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hotelData !== this.props.hotelData) {
      if (this.chart) {
        this.chart.dispose();
      }
      this.initializeGraph(
        this.props.hotelData,
        this.props.noOfDays,
        this.props.mainIndex
      );
    }
  }


  initializeGraph = (hotelData, noOfDays, mainIndex) => {
    console.log({hotelData, noOfDays,mainIndex })

    this.chart = am4core.create(`chartdiv_${mainIndex}`, am4charts.XYChart);
    
    let data = [];
    let open = 100;
    let close = 120;
    let fix = 20;
    for (let i = 0; i < hotelData.prices.length; i++) {

      open = Math.round(Math.random() < 0.5 ? 40 : 50);
      close = Math.round(Math.random() < 0.5 ? 40 : 50);
      data.push({
        category: moment(hotelData.prices[i].from_Date).format("yyyy-MM-DD"),
        open: hotelData.prices[i].competitorMaxPrice,
        close: hotelData.prices[i].competitorMinPrice,
        fix: hotelData.prices[i].price,
        occupancy: hotelData.prices[i].occupancy ? `${hotelData.prices[i].occupancy}%` : "0%",
      });
    }

    this.chart.data = data;
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.keepSelection = true;
    categoryAxis.startLocation = 0.4;
    categoryAxis.endLocation = 0.6;
    categoryAxis.tooltip.disabled = true;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    // valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.grid.template.strokeWidth = 0;
    valueAxis.renderer.width = 20;
    valueAxis.renderer.minLabelPosition = 0.05;
    valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.opposite = false;

    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "category";
    series.fill = am4core.color("green");
    series.stroke = am4core.color("rgb(187, 187, 187)");
    series.dataFields.openValueY = "open";
    series.dataFields.valueY = "close";
    series.tooltipText = "Comp High: {openValueY.value} Comp Low: {valueY.value}";
    series.sequencedInterpolation = true;
    series.fillOpacity = 0;
    series.strokeOpacity = 1;
    series.columns.template.width = 1;
    series.tooltip.pointerOrientation = "down";

    let openBullet = series.bullets.create(am4charts.CircleBullet);
    openBullet.locationY = 1;

    let closeBullet = series.bullets.create(am4charts.CircleBullet);

    // Create series
    let lineSeries = this.chart.series.push(new am4charts.LineSeries());
    lineSeries.fill = am4core.color("#4C337B");
    lineSeries.stroke = am4core.color("#4C337B");
    lineSeries.dataFields.categoryX = "category";
    lineSeries.dataFields.valueY = "fix";
    lineSeries.strokeWidth = 3;
    lineSeries.tooltipText = "Hotel Price: {fix}";
    lineSeries.name = "Fix";
    lineSeries.yAxis = valueAxis;

    // Add simple bullet
    let circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 2;

    let labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
    // labelBullet.label.text = "{fix}";
    labelBullet.label.dy = -20;

    closeBullet.fill = this.chart.colors.getIndex(4);
    closeBullet.stroke = closeBullet.fill;
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.behavior = "none";

    let valueAxis1 = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.tooltip.disabled = true;
    valueAxis1.renderer.ticks.template.disabled = true;
    valueAxis1.renderer.axisFills.template.disabled = true;
    valueAxis1.renderer.grid.template.strokeWidth = 0;
    valueAxis1.renderer.width = 20;
    valueAxis1.renderer.minLabelPosition = 0.05;
    valueAxis1.renderer.maxLabelPosition = 0.95;
    valueAxis1.renderer.opposite = true;

    let lineSeries1 = this.chart.series.push(new am4charts.LineSeries());
    lineSeries1.fill = am4core.color("#FFA500");
    lineSeries1.stroke = am4core.color("#FFA500");
    lineSeries1.dataFields.categoryX = "category";
    lineSeries1.dataFields.valueY = "occupancy";
    lineSeries1.strokeWidth = 1;
    lineSeries1.tooltipText = "Occupancy: {occupancy}";
    lineSeries1.name = "occupancy";
    lineSeries1.yAxis = valueAxis1;
    lineSeries1.strokeDasharray = "5,4";

    // Add simple bullet
    let circleBullet1 = lineSeries1.bullets.push(new am4charts.CircleBullet());
    circleBullet1.circle.stroke = am4core.color("#fff");
    circleBullet1.circle.strokeWidth = 2;

    let labelBullet1 = lineSeries1.bullets.push(new am4charts.LabelBullet());
    labelBullet1.label.dy = -20;
    let closeBullet1 = series.bullets.create(am4charts.CircleBullet);

    closeBullet1.fill = this.chart.colors.getIndex(4);
    closeBullet1.stroke = labelBullet1.fill;
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.behavior = "none";
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { hotelData, noOfDays, mainIndex } = this.props;
    return (
      <div className="graphContainer-outer">
        <ul>
          {hotelData &&
            hotelData.prices.map((item, index) => {
              if (index < noOfDays) {
                return <li key={index}></li>;
              } else {
                return false;
              }
            })}
        </ul>
        <div className="graph-container" id={`chartdiv_${mainIndex}`}></div>
      </div>
    );
  }
}

export default GraphData;
