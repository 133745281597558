import {
  GET_REVIEW_FORM_DATA_BY_HOTEL_ID,
  GET_REVIEW_FORM_LIST_DATA
} from '../constants/action.constant'

const initialState = {
  reviewFormList: [],
  reviewFormDataByHotelId: null
}

const getReviweFormList = (state, { list }) => ({ ...state, reviewFormList: list })

const getReviweFormData = (state, { reviewFormData }) => ({ ...state, reviewFormDataByHotelId: reviewFormData })

const reviewFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEW_FORM_LIST_DATA: 
    return getReviweFormList(state, action)
    case GET_REVIEW_FORM_DATA_BY_HOTEL_ID: 
    return getReviweFormData(state, action)
    default: return state
  }
}

export default reviewFormReducer
