import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserData } from "../../actions/user-management.action";
import $ from "jquery";
import { Accordion, Icon } from "semantic-ui-react";

class Navigationbars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      activeIndex: -1,
    };
  }

  componentDidMount() {
    const { getUserData } = this.props;
    getUserData();
  }

  handleSideBar = () => {
    $("body").removeClass("sideBar");
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { userData } = this.props;
    const { activeIndex } = this.state;
    return (
      <div id="mySidebar" class="sidebar">
        <span
          style={{ cursor: "pointer" }}
          class="closebtn"
          onClick={this.handleSideBar}
        >
          ×
        </span>
        <Link to={"/"} className="dashBoard">
          Dashboard
        </Link>
        {userData && userData.role !== "Client" && (
          <Link to={"/hotel-list"} className="admin">
            Manage Hotels
          </Link>
        )}

        {userData &&
          userData.role !== "Client" &&
          userData.role !== "Resource Manager" && (
            <Link to={"/user-management"} className="userManagement">
              User Management
            </Link>
          )}

        {/* {userData && (
          <Link to={"/event-management"} className="eventManagement">
            Event Management
          </Link>
        )} */}

        {userData && userData.role === "Admin" && (
          <Link to={"/review-form/list"} className="dashBoard">
            Hotel Review Form
          </Link>
        )}

        {userData && userData.role !== "Client" && (
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
              style={{
                color: "#777",
                fontSize: "16px",
                lineHeight: "22px",
              }}
            >
              <Icon name="dropdown" />
              Reports
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                <a
                  style={{
                    padding: "17px",
                    color: "#777",
                    fontSize: "16px",
                    lineHeight: "22px",
                    paddingLeft: "41px",
                  }}
                  href={
                    "https://lookerstudio.google.com/u/0/reporting/5b8d36d3-c079-4885-8e76-00fc91f9e11c/page/0lm3C"
                  }
                  target="_blank"
                >
                  Reputaion Reports
                </a>
              </p>
              <p>
                <a
                  style={{
                    padding: "17px",
                    color: "#777",
                    fontSize: "16px",
                    lineHeight: "22px",
                    paddingLeft: "41px",
                  }}
                  href={
                    "https://lookerstudio.google.com/u/0/reporting/f2a773a2-6ed1-4b11-bcb8-e2b86f6f6b9c/page/07b2C"
                  }
                  target="_blank"
                >
                  Revenue Reports
                </a>
              </p>
            </Accordion.Content>
          </Accordion>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: bindActionCreators(getUserData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbars);
