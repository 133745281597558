export const createUrl = (url, params, query) => {

    params && Object.keys(params).forEach((key) => {
        let val = '';
        val = params[key] && params[key].toString() && params[key].toString().replace(/[&\/\\#,+ ()$~%.'":*?<>{}!@`^;_]/g,"-");

        url = url && url.replace(`/:${key}`, `/${val}`);
    });
    query && typeof query === "object" && Object.keys(query).forEach((key, index) => {
         url += `${(index) ? '&' : '?'}${key}=${query[key]}`;
    });
    url = url.replace('#', '');
    return url;
};

export const queryStringToObject = (queryString = {}) => {
    if (queryString.length===0)
        return queryString;
    let search = queryString.substring(1);
    return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
};