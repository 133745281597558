import React, { Component } from "react";
import Calander from "./Calander";
import Hotel from "./Hotel";
//import moment from "moment";
import moment from "moment-timezone";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  fetchDashboardData,
  updateAIPrice,
  downloadReport,
  fetchHotelSearchResults,
} from "../../actions/dashboard.action";
import { Loader } from "../Loader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const styles = (theme) => ({
  select: {
    "&:after": {
      borderColor: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  label: {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  },
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.noOfDays = 14;
    this.state = {
      startDate:  new Date(),
      pageNumber: 1,
      hotelList: [],
      searchText: "",
      sort: "asc",
    };
    this.loadingRef = React.createRef();
  }
  changeCurrentDate = (isNext = false, currentDateParam) => {
    let currentDate = currentDateParam;
    isNext
      ? currentDate.setDate(currentDate.getDate() + this.noOfDays)
      : currentDate.setDate(currentDate.getDate() - this.noOfDays);
    this.setState(
      {
        startDate: new Date(currentDate),
        pageNumber: 1,
      },
      () => {
        this.props.fetchDashboardData(
          moment(this.state.startDate).format("yyyy-MM-DD"),
          true,
          1,
          this.state.searchText,
          true
        );
      }
    );
  };
  datePickerValue = (e) => {
    this.setState(
      {
        startDate: new Date(e.target.value),
        pageNumber: 1,
      },
      () => {
        this.props.fetchDashboardData(
          moment(this.state.startDate).format("yyyy-MM-DD"),
          true,
          1,
          this.state.searchText,
          true
        );
      }
    );
  };

  componentDidMount() {
    // this.props.getDashboardData();
    const { fetchDashboardData } = this.props;
    fetchDashboardData(
      moment(this.state.startDate).format("yyyy-MM-DD"),
      true,
      this.state.pageNumber
    );
  }

  pageChange = (event, value) => {
    const { fetchDashboardData } = this.props;

    fetchDashboardData(
      moment(this.state.startDate).format("yyyy-MM-DD"),
      true,
      value,
      this.state.searchText,
      false,
      this.state.sort
    );
    this.setState({ pageNumber: value });
  };

  downloadReport = () => {
    this.props.downloadReport(
      moment(this.state.startDate).format("yyyy-MM-DD"),
      this.state.pageNumber,
      this.state.searchText,
      true
    );
  };

  loadSearchData = (event) => {
    this.props.fetchHotelSearchResults(event.target.value, false);
  };

  onSearchSelected = (_, v) => {
    if (v) {
      this.setState({ searchText: v.name, pageNumber: 1 });
      this.props.fetchDashboardData(
        moment(this.state.startDate).format("yyyy-MM-DD"),
        true,
        1,
        v.name,
        true
      );
    } else {
      this.setState({ searchText: "", pageNumber: 1 });
      this.props.fetchDashboardData(
        moment(this.state.startDate).format("yyyy-MM-DD"),
        true,
        1,
        null,
        true
      );
    }
  };

  sortChange = (e) => {
    this.setState({ sort: e.target.value, pageNumber: 1 }, () => {
      this.props.fetchDashboardData(
        moment(this.state.startDate).format("yyyy-MM-DD"),
        true,
        1,
        "",
        true,
        this.state.sort
      );
    });
  };

  render() {
    // Additional css
    const loadingCSS = {
      height: "20px",
    };
    const { hotelList, updateAIPrice } = this.props;
    const { classes } = this.props;
    return (
      <div>
        <div
          className="calendar-container"
          style={{ position: "sticky", top: "70px", zIndex: 999 }}
        >
          <div className="container">
            <div className="row">
              <div id={"datePicker"} className="container">
                <div className="row align-items">
                  <div className="col-4 d-flex justify-content-center py-2">
                    <Autocomplete
                      id="combo-box-demo"
                      onChange={this.onSearchSelected}
                      options={this.props.hotelSearchResults}
                      getOptionLabel={(option) => option?.name}
                      style={{ width: 300, height: 40 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search hotel"
                          variant="outlined"
                          size="small"
                          onChange={this.loadSearchData}
                        />
                      )}
                    />
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <input
                      type="date"
                      value={moment(this.state.startDate).format("yyyy-MM-DD")}
                      onChange={this.datePickerValue}
                    />
                    <span className="dateText">
                      {moment(this.state.startDate).format("MMMM YYYY")}
                    </span>
                    <span
                      onClick={this.downloadReport}
                      className="downloadIcon"
                    >
                      <img src="/images/download.png" alt="Download" />
                    </span>
                  </div>
                  <div className="col-4 d-flex justify-content-center py-2">
                    <FormControl variant="outlined" size="small">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        className={classes.label}
                      >
                        Sort
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.sort}
                        onChange={this.sortChange}
                        label="Sort"
                        className={classes.select}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                            root: classes.root,
                          },
                        }}
                      >
                        <MenuItem value={"asc"}>A-Z</MenuItem>
                        <MenuItem value={"desc"}>Z-A</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <Calander
                days={this.noOfDays}
                changeCurrentDate={this.changeCurrentDate}
                startDate={this.state.startDate}
              />
            </div>
          </div>
        </div>
        <div className="hotel-wrapper">
          <div className="container">
            <div className="row">
              {hotelList.hotelPrices && (
                <Hotel
                  searchText={this.state.searchText}
                  pageNumber={this.state.pageNumber}
                  noOfDays={this.noOfDays}
                  hotelList={hotelList.hotelPrices}
                  updateAIPrice={updateAIPrice}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row align-items dashboard-pagination">
          <div className="col-md-12 d-flex justify-content-center">
            {hotelList.totalPages && (
              <Pagination
                variant="outlined"
                count={hotelList.totalPages}
                page={this.state.pageNumber}
                onChange={this.pageChange}
                color="primary"
                showFirstButton
                showLastButton
              />
            )}
          </div>
        </div>

        {!hotelList.hotelPrices && <Loader />}

        <div
          ref={(loadingRef) => (this.loadingRef = loadingRef)}
          style={loadingCSS}
        ></div>
      </div>
    );
    //}
    //return <Loader />;
  }
}
const mapStateToProps = (state) => ({
  hotelList: state.dashboardReducer.hotels,
  hotelSearchResults: state.dashboardReducer.hotelSearchResults,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardData: bindActionCreators(fetchDashboardData, dispatch),
  updateAIPrice: bindActionCreators(updateAIPrice, dispatch),
  downloadReport: bindActionCreators(downloadReport, dispatch),
  fetchHotelSearchResults: bindActionCreators(
    fetchHotelSearchResults,
    dispatch
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Dashboard);
