import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TopBarProgress from "react-topbar-progress-indicator";
import Loader from './compenents/loader';
import AuthRoutes from './routes/route-management/auth-routes'
import { withRouter } from 'react-router'
import {
  getUserData
} from './actions/user-management.action'
import { getAccessToken, removeAccessToken } from './util/auth'
import _ from 'lodash'

TopBarProgress.config({
  barColors: {
    "0": "#ffff00",
    "1.0": "#ffff00",
  },
  shadowColor: "#fff"
});

class Gatekeeper extends Component {
  state = { accessToken: '' };

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      if (!getAccessToken()) {
        this.props.history.push('/login')
      } else {
        this.props.getUserData(null, false);
      }
    }
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.isLoggedIn != this.props.isLoggedIn || !_.isEqual(prevProps.userData, this.props.userData )) {
      if (!this.props.isLoggedIn) {
        removeAccessToken()
        this.props.history.replace('/login')
      }
    }
    return null
  }                                                     

  render() {
    const { showLoader, overlay } = this.props;
    const showContent = <AuthRoutes />;

    return (
      <>
        <Loader isOpen={overlay} />
        {showLoader && <TopBarProgress />}
        {showContent}
      </>
    );
  }
}

const mapStateToProps = state => ({
  showLoader: state.common.showLoader,
  overlay: state.common.overlay,
  isLoggedIn: state.user.isLoggedIn,
  userData: state.user.userData,
});

const mapDispatchToProps = dispatch => ({
  getUserData: bindActionCreators(getUserData, dispatch),
});
const GatekeeperWithRouter = withRouter(Gatekeeper);

export default connect(mapStateToProps, mapDispatchToProps)(GatekeeperWithRouter);
