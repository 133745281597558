import React, { useState } from 'react'
import Modal from '../../compenents/modal/modal'
import './scss/add-user.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Input from '../../compenents/inputs/input-simple'
import { Grid } from '@material-ui/core'
import { Multiselect } from 'multiselect-react-dropdown'
import { makeStyles } from '@material-ui/core/styles'
import { addUser, editUser } from '../../actions/user-management.action'

function AddUser (props) {
  const { dialogClose, open, roles, hotels, existingUsers, editMode, user } = props
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('')
  const [projects, setProjects] = useState([])

  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [roleError, setRoleError] = useState(false)

  const useStyles = makeStyles({
    paperFullWidth: {
      // overflowY: 'visible'
    },
    dialogContentRoot: {
      // overflowY: 'visible'
    }
  })
  const classes = useStyles()
  let preSelectedHotels = []
  const userRole = roles.find(r => r.id === (role || user.userRoleId))
  const roleName = userRole ? userRole.name : ''

  if (editMode) {
    preSelectedHotels = hotels && hotels.filter(hotel => user.projectIds && user.projectIds.includes(hotel.id))
  }

  const onSelect = (selectedList, selectedItem) => {
    setProjects(selectedList)
  }

  const onRemove = (selectedList, selectedItem) => {
    setProjects(selectedList)
  }

  const changeHandler = e => {
    if (e.target.name === 'firstName') {
      setFirstName(e.target.value.trim())
      setFirstNameError(false)
      if (e.target.value.trim() === '') {
        setFirstNameError('This field is Required')
      }
    }
    if (e.target.name === 'lastName') {
      setLastName(e.target.value.trim())
      setLastNameError(false)
      if (e.target.value.trim() === '') {
        setLastNameError('This field is Required')
      }
    }
    if (e.target.name === 'email') {
      setEmail(e.target.value.trim())
      setEmailError(false)
      if (e.target.value.trim() === '') {
        setEmailError('This field is Required')
      }
    }
    if (e.target.name === 'password') {
      setPassword(e.target.value.trim())
      setPasswordError(false)
      if (e.target.value.trim() === '') {
        setPasswordError('This field is Required')
      }
    }
    if (e.target.name === 'role') {
      setRole(e.target.value.trim())
      setRoleError(false)
      if (e.target.value.trim() === '') {
        setRoleError('This field is Required')
      }
    }
  }

  function validateEmail (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submitHandler = () => {
    let valid = true
    if ((firstName || user.firstName) === '' || (firstName || user.firstName) === undefined) {
      setFirstNameError('This field is Required')
      valid = false
    }
    if ((lastName || user.lastName) === '' || (lastName || user.lastName) === undefined) {
      setLastNameError('This field is Required')
      valid = false
    }
    if ((email || user.email) === '' || (email || user.email) === undefined) {
      setEmailError('This field is Required')
      valid = false
    }
    if (email !== '' && existingUsers.includes(email)) {
      setEmailError('This email is already registered')
      valid = false
    }
    if ((password || user.password) === '' || (password || user.password) === undefined) {
      setPasswordError('This field is Required')
      valid = false
    }
    if ((role || user.userRoleId) === '' || (role || user.userRoleId) === undefined) {
      setRoleError('This field is Required')
      valid = false
    }
    if (email !== "" && validateEmail(email) === false) {
      setEmailError("Please enter valid email address");
      valid = false;
    }

    const projectIds = projects.map(p => p.id)

    if (valid) {
      if (editMode) {
        props.editUser({
          userId: user.id,
          firstName: firstName || user.firstName,
          lastName: lastName || user.lastName,
          email: email || user.email,
          password: password || user.password,
          userRoleId: role || user.userRoleId,
          projectIds: projectIds.length ? projectIds : (user.projectIds || [])
        })
          .then(res => {
            props.dialogClose()
            props.refresh()
          })
      } else {
        props.addUser({ firstName, lastName, email, password, userRoleId: role, projectIds }).then(res => {
          props.dialogClose()
          props.refresh()
        })
      }
    }
  }
  return (
    <Modal
      open={open}
      onClose={dialogClose}
      class='sm'
      fullWidth='true'
      modalHeading={editMode ? 'Edit User' : 'Create User'}
      modalSaveTxt='Save'
      modalCloseTxt='Cancel'
      onClick={submitHandler}
      showActionBtn
      savebtnClass='createBtn'
      dialogClasses={{ paperFullWidth: classes.paperFullWidth }}
      dialogContentclasses={{ root: classes.dialogContentRoot }}
      name
    >
      <div>
        <Grid xs={12} container className='subtaskrow-1'>
          <Grid xs={12} className='label' item >
            Firstname
          </Grid>
          <Grid xs={12} item>
            <Input
              name='firstName'
              type='text'
              label='Firstname'
              className='txtarea'
              error={firstNameError}
              valid={!firstNameError}
              value={firstName || (user.firstName || '')}
              touched
              onChange={(e) => changeHandler(e)}
            />
          </Grid>
        </Grid>
        <Grid xs={12} container className='subtaskrow-1'>
          <Grid xs={12} className='label' item>
            Lastname
          </Grid>
          <Grid xs={12} item>
            <Input
              name='lastName'
              type='text'
              label='Lastname'
              className='txtarea'
              error={lastNameError}
              valid={!lastNameError}
              value={lastName || (user.lastName || '')}
              touched
              onChange={(e) => changeHandler(e)}
            />
          </Grid>
        </Grid>
        <Grid xs={12} container className='subtaskrow-1'>
          <Grid xs={12} className='label' item>
            Email
          </Grid>
          <Grid xs={12} item>
            <Input
              name='email'
              type='text'
              label='Email'
              className='txtarea'
              error={emailError}
              valid={!emailError}
              value={email || (user.email || '')}
              touched
              onChange={(e) => changeHandler(e)}
            />
          </Grid>
        </Grid>
        <Grid xs={12} container className='subtaskrow-1'>
          <Grid xs={12} className='label' item>
            Password
          </Grid>
          <Grid xs={12} item>
            <Input
              name='password'
              type='text'
              label='Password'
              className='txtarea'
              error={passwordError}
              valid={!passwordError}
              value={password || (user.password || '')}
              touched
              onChange={(e) => changeHandler(e)}
            />
          </Grid>
        </Grid>
        <Grid xs={12} container className='subtaskrow-1'>
          <Grid xs={12} className='label' item>
            Role
          </Grid>
          <Grid xs={12} item>
            <Input
              name='role'
              type='select'
              label='Role'
              className='txtarea'
              error={roleError}
              valid={!roleError}
              touched
              items={roles.map(role => {
                return {
                  key: role.name,
                  value: role.id
                }
              })}
              value={role || user.userRoleId}
              onChange={(e) => changeHandler(e)}
            />
          </Grid>
        </Grid>
        <Grid xs={12} container className='subtaskrow-1'>
          {
            roleName !== 'Admin' &&
              <Grid xs={12} className='label' item>
                Map Hotels
              </Grid>
          }
          {
            roleName !== 'Admin' &&
              <Grid className='mapHotelGrid' xs={12} item>
                <Multiselect
                  options={hotels}
                  displayValue='name'
                  selectedValues={preSelectedHotels}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  className="map-hotel"
                />
              </Grid>
          }
        </Grid>
      </div>
    </Modal>
  )
}
const mapStateToProps = state => ({
})
const mapDispatchToProps = dispatch => ({
  addUser: bindActionCreators(addUser, dispatch),
  editUser: bindActionCreators(editUser, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(AddUser)
