import {PATH_URL} from "../constants/route.constants";
import HotelList from "../Component/HotelList";
import Dashboard from "../Component/Dashboard";
import UserManagement from "../Component/UserManagement";
import editHotel from "../Component/editHotel";
import ReviewFormList from "../Component/ReviewForm/review-form-list";
import CreateReviewForm from "../Component/ReviewForm/create-review-form";
import EventsList from "../Component/EventManagement/events-list";

export const routes = [
  {
    path: PATH_URL.HOME,
    component: Dashboard,
    //component:HotelList,
    exact: true,
  },
  {
    path: PATH_URL.HOTEL_LIST,
    component: HotelList,
    exact: true,
  },
  {
    path: PATH_URL.USER_MANAGEMENT,
    component: UserManagement,
    exact: true,
  },
  {
    path: PATH_URL.HOTEL_EDIT,
    component: editHotel,
    exact: true,
  },
  {
    path: PATH_URL.REVIEW_FORM_LIST,
    component: ReviewFormList,
    exact: true,
  },
  {
    path: PATH_URL.REVIEW_FORM_CREATE,
    component: CreateReviewForm,
    exact: true,
  },
  {
    path: PATH_URL.REVIEW_FORM_EDIT,
    component: CreateReviewForm,
    exact: true,
  },
  {
    path: PATH_URL.EVENT_MANAGEMENT,
    component: EventsList,
    exact: true,
  },
];