import axios from 'axios'
import { terminateSession } from '../actions/authConfig.action'
import { hideErrorPage, showErrorPage } from '../actions/common.action'
import { logout } from "../actions/user-management.action";

export const handleFetchError = (error, dispatch) => {
  if (error && error.response && error.response.status === 401 && dispatch) {
    dispatch(logout());
  }

  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status >= 400 &&
    dispatch
  ) {
    dispatch(hideErrorPage());
    dispatch(showErrorPage("is503", true));
  }
  if (axios.isCancel(error)) {
    console.error("Request cancelled:", error.message);
  } else if (error.response && error.response.status === 409 && dispatch) {
    dispatch(terminateSession());
  } else {
    console.log(error);
  }
};

export const internalServerError = (res, dispatch) => {
  if (res.data && res.data.success === false && dispatch) {
    dispatch(hideErrorPage())
    dispatch(showErrorPage('is500', true))
  }
}
