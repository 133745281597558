import { BEConfig } from "../config/env";
import {
  deleteWebApi,
  fetchWebApi,
  postAuthWebApi,
} from "../webapis/apiResource";
import { getAccessToken } from "../util/auth";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  successSnackBar,
  errorSnackBar,
} from "./common.action";
import { handleFetchError } from "../util/errorHandler";
import {
  GET_REVIEW_FORM_DATA_BY_HOTEL_ID,
  GET_REVIEW_FORM_LIST_DATA,
} from "../constants/action.constant";
import store from "../store";

// export const getReviewFormListData = list => ({ type: GET_REVIEW_FORM_LIST_DATA, list })

// export const getReviewFormByHotelId = reviewFormData => ({ type: GET_REVIEW_FORM_DATA_BY_HOTEL_ID, reviewFormData })

// export const fetchReviewFormListData = (showLoader = true) => {
//   const { baseURL, protocol, port, versionInfo, reviewFormList } = BEConfig
//   const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${reviewFormList}`
//   return (dispatch, getState) => {
//     if (showLoader) dispatch(showLoadingIndicator())
//     return fetchWebApi(getAccessToken(getState), apiURL)
//       .then(response => {
//         if (showLoader) dispatch(hideLoadingIndicator())
//         if (response.data && response.data.length) {
//           dispatch(getReviewFormListData(response.data))
//         }
//         return response
//       })
//       .catch(error => {
//         if (showLoader) dispatch(hideLoadingIndicator())
//         handleFetchError(error, dispatch)
//         return error.response
//       })
//   }
// }

export const fetchHotelEvents = (
  { startDate, endDate, hotelId = null, page = 0 },
  showLoader = true
) => {
  const { baseURL, protocol, port, versionInfo, getHotelEvents } = BEConfig;
  let apiURL = `${protocol}${baseURL}:${port}${versionInfo}${getHotelEvents}?startDate=${startDate}&endDate=${endDate}&pageNumber=${page}`;

  if (hotelId) {
    apiURL += `&hotelId=${hotelId}`;
  }

  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const createEvent = (data, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, addEvent } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${addEvent}`;

  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return postAuthWebApi(getAccessToken(), apiURL, data)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(successSnackBar("Hotel Event Added Succesfully"));
        return response;
      })
      .catch((error) => {
        store.dispatch(
          errorSnackBar("Something went wrong, please try again.")
        );
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const deleteEvent = (eventId, showLoader = true) => {
  const { baseURL, protocol, port, versionInfo, deleteEvent } = BEConfig;
  const apiURL = `${protocol}${baseURL}:${port}${versionInfo}${deleteEvent}/${eventId}`;

  return (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(), apiURL)
      .then((response) => {
        if (showLoader) dispatch(hideLoadingIndicator());
        store.dispatch(successSnackBar("Hotel Event Deleted Succesfully"));
        return response;
      })
      .catch((error) => {
        store.dispatch(
          errorSnackBar("Something went wrong, please try again.")
        );
        if (showLoader) dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
